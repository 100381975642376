import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import MainLayout from "../../components/MainLayout/MainLayout";
import Card from "../components/Card";
import SchemFooter from "../components/SchemFooter";

const AutoControlSystem = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Цех автоматизированных систем управления технологическими процессамм
            станции
          </div>
        </div>

        <div className="node__list" style={{ height: 800, width: 1900 }}>
          <Card
            id={84}
            width={300}
            height={90}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={1000}
          ></Card>

          <Card
            id={85}
            width={300}
            height={90}
            fontSize={14}
            addClass="start2 icon-start icon-mini"
            b={230}
            l={1000}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 50,
                height: 90,
                width: 10,
                borderRight: "4px solid #d73b13",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 20,
                left: -307,
                height: 90,
                width: 300,
                borderTop: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -9,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 5, left: 180 }}
              >
                <div>
                  <p style={{ fontSize: "10px" }}>
                    2 года - высшее <br />
                    профессиональное <br />
                    образование,
                    <br />
                    3 года - начальное <br />
                    профессиональное <br />
                    образование
                  </p>
                </div>
              </div>
            </div>
          </Card>

          <Card
            id={86}
            width={300}
            height={90}
            fontSize={14}
            addClass="start2 icon-start icon-mini"
            b={360}
            l={1000}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 50,
                height: 90,
                width: 10,
                borderRight: "4px solid #d73b13",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 5, left: 80 }}
              >
                <div>
                  <p style={{ fontSize: "10px" }}>
                    Высшее или среднее профессиональное образование,
                    <br />
                    опыт работы не менее 3 лет/1 года на должностях <br />
                    старшего оперативного персонала котельного отделения, <br />
                    турбинного отделения, электрического цеха.
                  </p>
                </div>
              </div>
            </div>
          </Card>

          <Card
            id={228}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            fontSize={14}
            b={230}
            l={550}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: -200,
                height: 35,
                width: 250,
                borderLeft: "4px solid #aab2ba",
                borderBottom: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: -2,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 45, left: 75 }}
              >
                <div>
                  <p style={{ fontSize: "10px" }}>
                    3 года - высшее <br />
                    профессиональное <br />
                    образование
                  </p>
                </div>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 115,
                right: -200,
                height: 40,
                borderLeft: "4px solid #aab2ba",
              }}
            ></div>
          </Card>

          <Card
            id={226}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            fontSize={14}
            b={380}
            l={330}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 30,
                height: 90,
                width: 10,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={87}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            fontSize={14}
            b={500}
            l={550}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 250,
                height: 90,
                width: 10,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 0, right: 20 }}
              >
                <div>
                  <p style={{ fontSize: 10 }}>
                    1 год - высшее <br />
                    профессиональное образование
                  </p>
                </div>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 44,
                height: 230,
                width: 10,
                borderRight: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 60,
                right: -205,
                height: 90,
                width: 200,
                borderRight: "4px solid #aab2ba",
                borderTop: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -12,
                  left: -8,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={88}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            fontSize={14}
            b={630}
            l={550}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 44,
                height: 90,
                width: 10,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 10, right: 20 }}
              >
                <div>
                  <p style={{ fontSize: 10 }}>
                    5 лет - высшее <br />
                    профессиональное образование
                  </p>
                </div>
              </div>
            </div>
          </Card>

          <Card
            id={89}
            width={300}
            height={90}
            fontSize={14}
            addClass="end icon-other icon-mini"
            b={550}
            l={1000}
          >
            <div
              className="node__arrow"
              style={{
                top: 60,
                left: -165,
                height: 90,
                width: 160,
                borderTop: "4px solid #5B646E",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -8,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={90}
            width={300}
            height={90}
            fontSize={14}
            addClass="end icon-other icon-mini"
            b={670}
            l={1000}
          >
            <div
              className="node__arrow"
              style={{
                top: 60,
                left: -105,
                height: 123,
                width: 100,
                borderTop: "4px solid #5B646E",
                borderLeft: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -8,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
        </div>
        <SchemFooter />
      </>
    </MainLayout>
  );
};

export default AutoControlSystem;
