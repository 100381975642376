import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonBack from "../ButtonBack/ButtonBack";
import MainLayout from "../MainLayout/MainLayout";

type Props = {
  handleDepartment: (val: string) => void;
};

const HeatAndPower = ({ handleDepartment }: Props) => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div className="container">
        <div className="back-wrpper">
          <ButtonBack
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div className="title">Теплоэлектроцентраль</div>
        <div className="heating-network__wrapper">
          <div className="wrapper">
            <img
              className="heating-network__bg"
              src="./images/heat-power/heat-power_bg.svg"
              alt=""
            />
            <Link to="/autocontrolsystem">
              <img
                src="./images/heat-power/circles/apc_system.svg"
                alt=""
                className="heating-network__circle center right"
                // onClick={() => {
                //   handleDepartment("3");
                // }}
              />
            </Link>
            <Link to="/centralpowerplantrepairservice">
              <img
                src="./images/heat-power/circles/repair.svg"
                alt=""
                className="heating-network__circle left center"
                // onClick={() => {
                //   handleDepartment("5");
                // }}
              />
            </Link>
            <Link to="/developing">
              <img
                src="./images/heat-power/circles/departments.svg"
                alt=""
                className="heating-network__circle left bottom"
                // onClick={() => {
                //   handleDepartment("4");
                // }}
              />
            </Link>
            <Link to="/electricalworkshop">
              <img
                src="./images/heat-power/circles/electrical_service.svg"
                alt=""
                className="heating-network__circle left top"
                // onClick={() => {
                //   handleDepartment("2");
                // }}
              />
            </Link>
            <Link to="/boilerpipes">
              <img
                src="./images/heat-power/circles/boiler-turbine.svg"
                alt=""
                className="heating-network__circle bottom right"
                // onClick={() => {
                //   handleDepartment("4");
                // }}
              />
            </Link>
            <Link to="/chemicaldepartment">
              <img
                src="./images/heat-power/circles/chemical_service.svg"
                alt=""
                className="heating-network__circle top right"
                // onClick={() => {
                //   handleDepartment("1");
                // }}
              />
            </Link>
          </div>
          <Link to="/chemicaldepartment">
            <div
              className="heating-network__block right top"
              // onClick={() => {
              //   handleDepartment("1");
              // }}
            >
              <img
                src="./images/heating-network/circles/arrows/arrow_1.svg"
                alt=""
                className="heating-network__block-arrow"
              />
              <div className="heating-network__block-icon">
                <img
                  src="./images/heating-network/circles/icons/flask-vial.svg"
                  alt=""
                />
              </div>
              <div className="heating-network__block-text">
                <div className="heating-network__block-title">
                  Структурное подразделение
                </div>
                <div className="heating-network__block-subtitle">
                  Химический цех
                </div>
              </div>
            </div>
          </Link>
          <Link to="/autocontrolsystem">
            <div
              className="heating-network__block right center orange"
              // onClick={() => {
              //   handleDepartment("3");
              // }}
            >
              <img
                src="./images/heating-network/circles/arrows/arrow_2.svg"
                alt=""
                className="heating-network__block-arrow"
              />
              <div className="heating-network__block-icon">
                <img
                  src="./images/heating-network/circles/icons/network-wired.svg"
                  alt=""
                />
              </div>
              <div className="heating-network__block-text">
                <div className="heating-network__block-title">
                  Структурное подразделение
                </div>
                <div className="heating-network__block-subtitle">Цех АСУТП</div>
              </div>
            </div>
          </Link>
          <Link to="/boilerpipes">
            <div
              className="heating-network__block right bottom blue"
              // onClick={() => {
              //   handleDepartment("4");
              // }}
            >
              <img
                src="./images/heating-network/circles/arrows/arrow_3.svg"
                alt=""
                className="heating-network__block-arrow"
              />
              <div className="heating-network__block-icon">
                <img
                  src="./images/heating-network/circles/icons/industry-windows.svg"
                  alt=""
                />
              </div>
              <div className="heating-network__block-text">
                <div className="heating-network__block-title">
                  Структурное подразделение
                </div>
                <div className="heating-network__block-subtitle">
                  Котлотурбинный цех
                </div>
              </div>
            </div>
          </Link>
          <Link to="/developing">
            <div
              className="heating-network__block left bottom green"
              // onClick={() => {
              //   handleDepartment("4");
              // }}
            >
              <img
                src="./images/heating-network/circles/arrows/arrow_7.svg"
                alt=""
                className="heating-network__block-arrow"
              />
              <div className="heating-network__block-icon">
                <img
                  src="./images/heating-network/circles/icons/screwdriver.svg"
                  alt=""
                />
              </div>
              <div className="heating-network__block-text">
                <div className="heating-network__block-title">
                  Структурное подразделение
                </div>
                <div className="heating-network__block-subtitle">
                  Отделы ТЭЦ
                </div>
              </div>
            </div>
          </Link>
          <Link to="/centralpowerplantrepairservice">
            <div
              className="heating-network__block left center turquoise"
              // onClick={() => {
              //   handleDepartment("5");
              // }}
            >
              <img
                src="./images/heating-network/circles/arrows/arrow_5.svg"
                alt=""
                className="heating-network__block-arrow"
              />
              <div className="heating-network__block-icon">
                <img
                  src="./images/heating-network/circles/icons/fire-burner.svg"
                  alt=""
                />
              </div>
              <div className="heating-network__block-text">
                <div className="heating-network__block-title">
                  Структурное подразделение
                </div>
                <div className="heating-network__block-subtitle">
                  Ремонтная служба
                </div>
              </div>
            </div>
          </Link>
          <Link to="/electricalworkshop">
            <div
              className="heating-network__block left top purple"
              // onClick={() => {
              //   handleDepartment("2");
              // }}
            >
              <div className="heating-network__block-icon">
                <img
                  src="./images/heating-network/circles/icons/bolt.svg"
                  alt=""
                />
              </div>
              <div className="heating-network__block-text">
                <div className="heating-network__block-title">
                  Структурное подразделение
                </div>
                <div className="heating-network__block-subtitle">
                  Электрический цех
                </div>
              </div>
              <img
                src="./images/heating-network/circles/arrows/arrow_6.svg"
                alt=""
                className="heating-network__block-arrow"
              />
            </div>
          </Link>
        </div>
      </div>
    </MainLayout>
  );
};

export default HeatAndPower;
