import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import MainLayout from "../../components/MainLayout/MainLayout";
import Card from "../components/Card";
import SchemFooter from "../components/SchemFooter";

const ElectricalWorkshop = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Электрический цех электростанции
          </div>
        </div>

        <div className="node__list" style={{ height: 970, width: 1900 }}>
          <Card
            id={68}
            addClass="start icon-start icon-mini"
            width={300}
            height={90}
            b={100}
            l={450}
            fontSize={14}
          ></Card>
          <Card
            id={69}
            addClass="start icon-start icon-mini"
            width={300}
            height={90}
            b={100}
            l={800}
            fontSize={14}
          ></Card>

          <Card
            id={70}
            addClass="worker icon-worker icon-mini"
            width={300}
            height={90}
            b={230}
            l={450}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: 60,
                left: "100%",
                width: 100,
                height: 50,
                borderTop: "4px solid #D73B13",
                borderRight: "4px solid #D73B13",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%) rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#D73B13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-brecket down"
                style={{
                  borderColor: "#D73B13",
                  width: 700,
                  left: 25,
                }}
              ></div>
            </div>
          </Card>
          <Card
            id={71}
            addClass="worker icon-worker icon-mini"
            width={300}
            height={90}
            b={230}
            l={1150}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: 60,
                right: "100%",
                width: 100,
                height: 50,
                borderTop: "4px solid #D73B13",
                borderRight: "4px solid #D73B13",
                transform: "rotateY(180deg)",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%) rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#D73B13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={72}
            addClass="start worker icon-worker icon-mini"
            width={300}
            height={90}
            b={230}
            l={1500}
            fontSize={14}
          ></Card>

          <Card
            id={73}
            addClass="worker icon-worker icon-mini"
            width={300}
            height={90}
            b={360}
            l={450}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 250,
                height: 70,
                borderLeft: "4px solid #D73B13",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#D73B13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={74}
            addClass="supervisor icon-supervisor icon-mini"
            width={400}
            height={90}
            b={360}
            l={1275}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 300,
                height: 70,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 100,
                height: 70,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={75}
            addClass="worker icon-worker icon-mini"
            width={300}
            height={90}
            b={490}
            l={450}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 250,
                height: 50,
                borderLeft: "4px solid #D73B13",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#D73B13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 60,
                left: "100%",
                width: 30,
                height: 220,
                borderTop: "4px solid #D73B13",
                borderRight: "4px solid #D73B13",
                borderBottom: "4px solid #D73B13",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%) rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#D73B13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  fontSize: 11,
                  top: 100,
                  left: 40,
                  position: "absolute",
                }}
              >
                <div>высшее или среднее</div>
                <div>профессиональное образование</div>
                <div>1 год электромонтером</div>
              </div>
            </div>
          </Card>
          <Card
            id={76}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            b={490}
            l={800}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: 30,
                right: "100%",
                width: 70,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{ transform: "none", left: "auto", right: -2, top: -12 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={230}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            b={490}
            l={1150}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 70,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={12}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            b={490}
            l={1500}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 250,
                height: 250,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  fontSize: 11,
                  top: 60,
                  left: 10,
                  position: "absolute",
                }}
              >
                <div>Высшее</div>
                <div>профессиональное</div>
                <div>образование,</div>
                <div>3 года</div>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 75,
                height: 70,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={231}
            addClass="end icon-other icon-mini"
            width={300}
            height={90}
            b={640}
            l={100}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: 30,
                left: "100%",
                width: 60,
                borderTop: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%) rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={77}
            addClass="worker icon-worker icon-mini"
            width={300}
            height={90}
            b={640}
            l={450}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 250,
                height: 70,
                borderLeft: "4px solid #D73B13",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#D73B13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 60,
                right: "100%",
                width: 30,
                height: 240,
                borderTop: "4px solid #D73B13",
                borderLeft: "4px solid #D73B13",
                borderBottom: "4px solid #D73B13",
              }}
            >
              <div
                className="node__arrow-end"
                style={{ transform: "none", left: "auto", right: -2, top: -12 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#D73B13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={79}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            b={640}
            l={800}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: 30,
                right: "100%",
                width: 70,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{ transform: "none", left: "auto", right: -2, top: -12 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 150,
                height: 70,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 250,
                height: 30,
                width: 150,
                borderLeft: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow"
                style={{
                  top: "100%",
                  left: "100%",
                  height: 40,
                  borderLeft: "4px solid #AAB2BA",
                }}
              ></div>
            </div>
          </Card>
          <Card
            id={78}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            b={640}
            l={1150}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 70,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 60,
                left: "100%",
                width: 200,
                height: 90,
                borderTop: "4px solid #AAB2BA",
                borderRight: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%) rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  fontSize: 11,
                  top: 10,
                  left: 210,
                  position: "absolute",
                }}
              >
                <div>Высшее профессиональное</div>
                <div>образование, стаж работы</div>
                <div>на инженерно - технических</div>
                <div>должностях не менее 3 лет</div>
              </div>
            </div>
          </Card>

          <Card
            id={232}
            addClass="end icon-other icon-mini"
            width={300}
            height={90}
            b={750}
            l={100}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: 60,
                left: "100%",
                width: 30,
                height: 80,
                borderTop: "4px solid #5B646E",
                borderRight: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%) rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={82}
            addClass="end icon-other icon-mini"
            width={300}
            height={90}
            b={750}
            l={1500}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 250,
                height: 160,
                width: 490,
                borderRight: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow"
                style={{
                  top: "100%",
                  left: 0,
                  height: 20,
                  borderRight: "4px solid #5B646E",
                }}
              ></div>
              <div
                className="node__arrow"
                style={{
                  top: 40,
                  right: 0,
                  width: 100,
                  borderBottom: "4px solid #5B646E",
                }}
              ></div>
              <div
                className="node__arrow-text"
                style={{
                  fontSize: 11,
                  top: 20,
                  left: 500,
                  position: "absolute",
                }}
              >
                <div>Высшее профессиональное</div>
                <div>образование, стаж работы</div>
                <div>на инженерно - технических</div>
                <div>должностях не менее 3 лет</div>
              </div>
            </div>
          </Card>

          <Card
            id={167}
            addClass="worker icon-worker icon-mini"
            width={300}
            height={90}
            b={820}
            l={450}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 50,
                height: 100,
                borderLeft: "4px solid #D73B13",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#D73B13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  fontSize: 11,
                  top: 15,
                  left: 10,
                  position: "absolute",
                }}
              >
                <div>Высшее или среднее профессиональное образование,</div>
                <div>опыт работы не менее 3 лет/1 года на должностях</div>
                <div>старшего оперативного персонала котельного отделения,</div>
                <div>турбинного отделения, электрического цеха.</div>
                <div>Подготовка по должности 1 год</div>
              </div>
            </div>
          </Card>
          <Card
            id={80}
            addClass="supervisor icon-supervisor icon-mini"
            width={300}
            height={90}
            b={820}
            l={800}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 150,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  fontSize: 11,
                  top: 20,
                  left: 10,
                  position: "absolute",
                }}
              >
                <div>Высшее профессиональное</div>
                <div>образование, стаж работы</div>
                <div>на инженерно - технических</div>
                <div>должностях не менее 5 лет</div>
              </div>
            </div>
          </Card>

          <Card
            id={129}
            addClass="end icon-other icon-mini"
            width={300}
            height={90}
            b={870}
            l={1500}
            fontSize={14}
          >
            <div
              className="node__arrow"
              style={{
                top: 60,
                right: "100%",
                width: 410,
                borderTop: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{ transform: "none", left: "auto", right: -2, top: -12 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 40,
                height: 40,
                borderLeft: "4px solid #5B646E",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
        </div>
        <SchemFooter />
      </>
    </MainLayout>
  );
};

export default ElectricalWorkshop;
