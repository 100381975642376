import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import SchemFooter from "./components/SchemFooter";

import "./styles.scss";

const HeatingNetworks = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">Район тепловых сети</div>
        </div>
        <div className="node__list" style={{ height: 850, width: 1900 }}>
          <Card
            id={55}
            addClass="end icon-other"
            b={700}
            l={450}
            width={300}
            height={100}
          />
          <Card
            id={54}
            addClass="end icon-other"
            b={700}
            l={800}
            width={300}
            height={100}
          />
          <Card
            id={53}
            addClass="end icon-other"
            b={700}
            l={1150}
            width={300}
            height={100}
          />
          <Card
            id={52}
            addClass="end icon-other"
            b={700}
            l={1500}
            width={300}
            height={100}
          />

          <Card
            id={51}
            addClass="icon-supervisor"
            b={550}
            l={1500}
            width={300}
            height={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 150,
                height: 50,
                width: 200,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  position: "absolute",
                  left: 70,
                  top: 55,
                }}
              >
                <div>5 лет - высшее образование,</div>
                <div>3 года - управленческий стаж</div>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                bottom: 50,
                right: 246,
                height: 85,
                width: 428,
                borderLeft: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket"
                style={{ left: 0, top: -22, width: 1400 }}
              ></div>
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={50}
            addClass="icon-supervisor"
            b={475}
            l={1150}
            width={300}
            height={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 150,
                height: 50,
                width: 200,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={48}
            addClass="end icon-other"
            b={400}
            l={100}
            width={300}
            height={100}
          >
            <div
              className="node__arrow"
              style={{
                top: 40,
                left: "100%",
                width: 60,
                borderTop: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%) rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={47}
            addClass="icon-supervisor"
            b={400}
            l={450}
            width={300}
            height={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 100,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={49}
            addClass="icon-supervisor"
            b={400}
            l={800}
            width={300}
            height={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  position: "absolute",
                  left: 10,
                  top: 22,
                }}
              >
                <div>3 года - среднее профессиональное образование</div>
              </div>
            </div>
          </Card>

          <Card
            id={44}
            addClass="icon-supervisor"
            b={250}
            l={300}
            width={300}
            height={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 46,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  position: "absolute",
                  left: 10,
                  top: 12,
                }}
              >
                <div>Среднее профессиональное образование</div>
              </div>
            </div>
          </Card>
          <Card
            id={46}
            addClass="icon-supervisor"
            b={250}
            l={800}
            width={300}
            height={100}
          >
            <div
              className="node__arrow"
              style={{
                top: 40,
                left: "100%",
                width: 60,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%) rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 45,
                right: "100%",
                width: 230,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -2,
                  transform: "translateY(-50%)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  position: "absolute",
                  left: 50,
                  bottom: 10,
                }}
              >
                <div>1 год - среднее</div>
                <div>профессиональное</div>
                <div>образование</div>
              </div>
            </div>
          </Card>
          <Card
            id={45}
            addClass="icon-supervisor"
            b={250}
            l={1150}
            width={300}
            height={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 46,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  position: "absolute",
                  left: 10,
                  top: 1,
                }}
              >
                <div>1 год - высшее образование,</div>
                <div>3 года - среднее профессиональное образование</div>
              </div>
            </div>
          </Card>

          <Card
            id={31}
            addClass="start icon-start"
            b={100}
            l={100}
            width={300}
            height={100}
          />
          <Card
            id={40}
            addClass="start"
            b={100}
            l={450}
            width={300}
            height={100}
          />
          <Card
            id={41}
            addClass="start"
            b={100}
            l={800}
            width={300}
            height={100}
          >
            <div
              className="node__arrow-brecket down"
              style={{ bottom: 80, width: 1750 }}
            ></div>
          </Card>
          <Card
            id={42}
            addClass="start"
            b={100}
            l={1150}
            width={300}
            height={100}
          />
          <Card
            id={43}
            addClass="start icon-start"
            b={100}
            l={1500}
            width={300}
            height={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 225,
                width: 525,
                height: 50,
                borderLeft: "4px solid #D73B13",
                borderBottom: "4px solid #D73B13",
                borderRight: "4px solid #D73B13",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#D73B13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
        </div>
        <SchemFooter />
      </div>
    </MainLayout>
  );
};

export default HeatingNetworks;
