import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import PositionFooter from "./components/PositionFooter";

import "./styles.scss";

const Position09 = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Заместитель технического директора/ главного инженера станции по
            обеспечению технического состояния
          </div>
        </div>
        <div className="node__list" style={{ height: 800, width: 1900 }}>
          <Card
            id={217}
            addClass="corporate-center"
            b={600}
            l={100}
            width={350}
            height={130}
          ></Card>
          <Card
            id={176}
            addClass="branch"
            b={600}
            l={550}
            width={350}
            height={130}
          ></Card>
          <Card
            id={177}
            addClass="branch"
            b={600}
            l={1000}
            width={350}
            height={130}
          ></Card>
          <Card
            id={218}
            addClass="branch"
            b={600}
            l={1450}
            width={350}
            height={130}
          ></Card>

          <Card id={188} addClass="power-station" b={450} l={400}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 350,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={216} addClass="power-station big" b={250} l={725}>
            <div
              className="node__arrow"
              style={{
                top: 150,
                left: 220,
                height: 40,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket down"
                style={{ width: 1750 }}
              ></div>
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                bottom: "100%",
                left: 220,
                height: 180,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket"
                style={{ width: 1750 }}
              ></div>
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={109}
            addClass="power-station start"
            b={100}
            l={100}
            width={350}
          ></Card>
          <Card
            id={80}
            addClass="power-station start"
            b={100}
            l={550}
            width={350}
          ></Card>
          <Card
            id={215}
            addClass="power-station start"
            b={100}
            l={1000}
            width={350}
          ></Card>
          <Card
            id={82}
            addClass="power-station start"
            b={100}
            l={1450}
            width={350}
          ></Card>
        </div>
        <PositionFooter />
      </div>
    </MainLayout>
  );
};

// [217, 176, 177, 218, 188, 216, 109, 80, 215, 82] - Заместитель технического директора/ главного инженера станции по обеспечению технического состояния (key=09)

export default Position09;
