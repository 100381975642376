import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { url } from "../../components/Position/Position";

type Props = {
  id: number;
  children?: any;
  addClass?: string;
  b?: number;
  l?: number;
  width?: number;
  height?: number;
  fontSize?: number;
};

const Card = ({
  id,
  children,
  addClass,
  b,
  l,
  width,
  height,
  fontSize,
}: Props) => {
  const [positionData, setpositionData] = useState<{
    name: string;
    preview: string;
    id: number;
  }>({ name: "", preview: "", id: 0 });

  useEffect(() => {
    axios
      .get(url + "/position/one-by-id", {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        if (response.data.model) {
          setpositionData(response.data.model);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id]);

  return (
    <Link
      to={{
        pathname: "/pos",
      }}
      state={positionData}
    >
      <div
        className={
          "node " +
          (addClass ? addClass : "") +
          (positionData.preview ? " has-preview" : "")
        }
        style={{
          bottom: b + "px",
          left: l + "px",
          width: width + "px",
          height: height + "px",
          fontSize: fontSize + "px",
        }}
      >
        <div className="node__text">
          {positionData.name}
          {/* {positionData.name} - {positionData.id} */}
        </div>
        {positionData.preview && (
          <div className="node__img">
            <img src={positionData.preview} alt="" />
          </div>
        )}
        {children}
      </div>
    </Link>
  );
};

export default Card;
