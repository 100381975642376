import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import PositionFooter from "./components/PositionFooter";

import "./styles.scss";

const Position05 = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Заместитель главного инженера филиала по генерации (эксплуатации)
          </div>
        </div>
        <div className="node__list" style={{ height: 900, width: 1900 }}>
          <Card
            id={201}
            addClass="corporate-center"
            b={750}
            l={100}
            width={300}
            height={120}
          >
            <div
              className="node__arrow-brecket"
              style={{
                top: 110,
                left: -25,
                transform: "none",
                width: 1400,
              }}
            ></div>
          </Card>
          <Card
            id={202}
            addClass="corporate-center"
            b={750}
            l={450}
            width={300}
            height={120}
          ></Card>
          <Card
            id={203}
            addClass="corporate-center"
            b={750}
            l={800}
            width={300}
            height={120}
          ></Card>
          <Card
            id={204}
            addClass="corporate-center"
            b={750}
            l={1150}
            width={300}
            height={120}
          ></Card>

          <Card
            id={178}
            addClass="branch"
            b={700}
            l={1500}
            width={300}
            height={120}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 250,
                height: 30,
                width: 200,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={174}
            addClass="branch"
            b={580}
            l={1150}
            width={300}
            height={120}
          >
            <div
              className="node__arrow"
              style={{
                top: 80,
                left: "100%",
                width: 150,
                height: 100,
                borderTop: "4px solid #AAB2BA",
                borderRight: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%) rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 46,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                bottom: "100%",
                left: 225,
                height: 38,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={200}
            addClass="branch"
            b={400}
            l={100}
            width={300}
            height={120}
          >
            <div
              className="node__arrow-brecket down"
              style={{
                top: -20,
                left: -25,
                transform: "none",
                width: 1400,
              }}
            ></div>
          </Card>
          <Card
            id={134}
            addClass="branch"
            b={400}
            l={450}
            width={300}
            height={120}
          ></Card>
          <Card
            id={27}
            addClass="branch"
            b={400}
            l={800}
            width={300}
            height={120}
          ></Card>
          <Card
            id={177}
            addClass="branch"
            b={400}
            l={1150}
            width={300}
            height={120}
          ></Card>
          <Card
            id={188}
            addClass="power-station"
            b={400}
            l={1500}
            width={300}
            height={120}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 250,
                height: 100,
                width: 200,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={168}
            addClass="power-station"
            b={250}
            l={1150}
            width={300}
            height={120}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 250,
                height: 100,
                width: 200,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 250,
                height: 100,
                width: 200,
                borderLeft: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={80}
            addClass="power-station"
            b={100}
            l={800}
            width={300}
            height={120}
          ></Card>
          <Card
            id={109}
            addClass="power-station"
            b={100}
            l={1500}
            width={300}
            height={120}
          ></Card>
        </div>
        <PositionFooter />
      </div>
    </MainLayout>
  );
};

// [201, 202, 203, 204, 178, 174, 200, 134, 27, 177, 188, 168, 80, 109 ] - Заместитель главного инженера филиала по генерации (эксплуатации) (key=05)

export default Position05;
