import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import MainLayout from "../../components/MainLayout/MainLayout";
import Card from "../components/Card";
import SchemFooter from "../components/SchemFooter";

const BoilerPipes = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Котлотурбинный цех электростанции
          </div>
        </div>

        <div className="node__list" style={{ height: 1000, width: 1900 }}>
          <Card
            id={91}
            width={220}
            height={70}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={100}
          >
            <div
              className="node__arrow-brecket down"
              style={{
                left: -20,
                top: -20,
                width: 1500,
                transform: "none",
                borderColor: "#D73B13",
              }}
            ></div>
          </Card>
          <Card
            id={92}
            width={220}
            height={70}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={350}
          ></Card>
          <Card
            id={93}
            width={220}
            height={70}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={600}
          ></Card>
          <Card
            id={94}
            width={220}
            height={70}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={850}
          ></Card>
          <Card
            id={95}
            width={220}
            height={70}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={1100}
          ></Card>
          <Card
            id={96}
            width={220}
            height={70}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={1350}
          ></Card>

          <Card
            id={101}
            width={290}
            height={65}
            fontSize={14}
            addClass="worker icon-worker icon-mini"
            b={210}
            l={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 50,
                height: 28,
                borderRight: "4px solid #d73b13",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={97}
            width={290}
            height={65}
            fontSize={14}
            addClass="worker icon-worker icon-mini"
            b={210}
            l={400}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 50,
                height: 28,
                borderRight: "4px solid #d73b13",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={98}
            width={290}
            height={65}
            fontSize={14}
            addClass="worker icon-worker icon-mini"
            b={210}
            l={700}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 240,
                height: 28,
                borderRight: "4px solid #d73b13",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={102}
            width={290}
            height={65}
            fontSize={14}
            addClass="worker icon-worker icon-mini"
            b={305}
            l={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 50,
                height: 50,
                width: 400,
                borderRight: "4px solid #d73b13",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 8, right: 20 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    Стаж машинист - обходчик энергоблока
                    <br />
                    не менее 1 года
                  </p>
                </div>
              </div>
            </div>
          </Card>
          <Card
            id={99}
            width={290}
            height={65}
            fontSize={14}
            addClass="worker icon-worker icon-mini"
            b={305}
            l={400}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 50,
                height: 50,
                width: 10,
                borderRight: "4px solid #d73b13",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 8, right: 20 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    Стаж машинист - обходчик энергоблока
                    <br />
                    не менее 1 года
                  </p>
                </div>
              </div>
            </div>
          </Card>
          <Card
            id={100}
            width={290}
            height={65}
            fontSize={14}
            addClass="worker icon-worker icon-mini"
            b={305}
            l={700}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 240,
                height: 50,
                width: 400,
                borderRight: "4px solid #d73b13",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 8, right: -200 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    Стаж машинист - обходчик энергоблока
                    <br />
                    не менее 1 года
                  </p>
                </div>
              </div>
            </div>
          </Card>

          <Card
            id={103}
            width={290}
            height={65}
            fontSize={14}
            addClass="worker icon-worker icon-mini"
            b={400}
            l={100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 50,
                height: 50,
                width: 400,
                borderRight: "4px solid #d73b13",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 8, right: 20 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    Стаж работы на всех рабочих местах
                    <br />
                    энергоблока не менее 6 мес
                  </p>
                </div>
              </div>
            </div>
          </Card>
          <Card
            id={104}
            width={290}
            height={65}
            fontSize={14}
            addClass="worker icon-worker icon-mini"
            b={400}
            l={700}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 240,
                height: 50,
                width: 400,
                borderRight: "4px solid #d73b13",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 8, right: 140 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    Стаж работы на всех рабочих местах
                    <br />
                    энергоблока не менее 6 мес
                  </p>
                </div>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 8, right: -180 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    Стаж работы на всех рабочих местах
                    <br />
                    энергоблока не менее 6 мес
                  </p>
                </div>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 50,
                right: 290,
                height: 60,
                width: 80,
                borderLeft: "4px solid #d73b13",
                borderTop: "4px solid #d73b13",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -8,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={105}
            addClass="worker icon-worker icon-mini"
            width={245}
            height={70}
            fontSize={14}
            b={500}
            l={250}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 154,
                height: 50,
                width: 400,
                borderRight: "4px solid #d73b13",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 40,
                left: 245,
                height: 10,
                width: 100,
                // borderRight: "6px solid #d73b13",
                borderTop: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: -8,
                  top: -12,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-end"
                style={{
                  left: 85,
                  top: -12,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 8, right: 20 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    При условии
                    <br />
                    оперативной
                    <br />
                    работы
                  </p>
                </div>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 68,
                right: -230,
                height: 60,
                width: 280,
                borderLeft: "4px solid #d73b13",
                borderBottom: "4px solid #d73b13",
                transform: "rotate(0deg)",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -6,
                  right: "auto",
                  left: -2,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 65, right: 100 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    1 год - высшее образование,
                    <br />3 года среднее профессиональное образование
                  </p>
                </div>
              </div>
            </div>

            {/* <div
              className="node__arrow"
              style={{
                top: -202,
                left: 214,
                height: 220,
                width: 600,
                borderLeft: "4px solid  #5B646E",
                borderTop: "4px solid  #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -9,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 35, right: 610 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    Высшее
                    <br />
                    профессиональное
                    <br />
                    образование,
                    <br />
                    5 - 7 лет
                    <br />
                    общих стаж,
                    <br />
                    1 год
                    <br />
                    управленческий
                    <br />
                    стаж
                  </p>
                </div>
              </div>

              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 5, right: 0 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    Высшее профессиональное
                    <br />
                    образование, стаж работы
                    <br />
                    инженерно - техническим работником
                    <br />
                    не менее 5 лет
                  </p>
                </div>
              </div>
            </div> */}

            {/* <div
              className="node__arrow"
              style={{
                top: -270,
                left: 260,
                height: 280,
                width: 73,
                borderLeft: "4px solid  #aab2ba",
                borderTop: "4px solid  #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -10,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div> */}

            {/* <div
              className="node__arrow"
              style={{
                top: -330,
                left: 215,
                height: 345,
                width: 50,
                borderBottom: "4px solid  #aab2ba",
              }}
            ></div> */}
          </Card>
          <Card
            id={106}
            addClass="supersisor icon-supervisor icon-mini"
            width={245}
            height={70}
            fontSize={14}
            b={500}
            l={600}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 100,
                height: 50,
                width: 200,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -23,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 5, right: -200 }}
              >
                <div>
                  <p style={{ fontSize: 9, color: "black" }}>
                    Высшее образование,
                    <br />
                    среднее профессиональное образование,
                    <br />
                    стаж 1 год
                  </p>
                </div>
              </div>
            </div>
          </Card>

          <Card
            id={108}
            addClass="supersisor icon-supervisor icon-mini"
            width={245}
            height={80}
            fontSize={14}
            b={600}
            l={600}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: "80%",
                height: 50,
                width: 400,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={107}
            addClass="supersisor icon-supervisor icon-mini"
            width={245}
            height={80}
            fontSize={14}
            b={700}
            l={600}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: "80%",
                height: 50,
                width: 400,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 40,
                right: -21,
                height: 190,
                width: 20,
                borderRight: "4px solid #aab2ba",
                borderBottom: "4px solid #aab2ba",
                borderTop: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -12,
                  left: -2,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={109}
            addClass="supersisor icon-supervisor icon-mini"
            width={245}
            height={80}
            fontSize={14}
            b={830}
            l={600}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: "80%",
                height: 55,
                width: 400,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 60,
                left: -40,
                height: 230,
                width: 40,
                borderLeft: "4px solid #aab2ba",
                borderBottom: "4px solid #aab2ba",
                borderTop: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -2,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: -34,
                left: 204,
                height: 40,
                width: 320,
                borderLeft: "4px solid  #5B646E",
                borderTop: "4px solid  #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -9,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 40,
                left: 240,
                height: 50,
                width: 65,
                borderTop: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -6,
                  top: -12,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 20,
                left: -155,
                height: 350,
                width: 155,
                borderLeft: "4px solid #aab2ba",
                borderTop: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -2,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={26}
            addClass="supersisor icon-supervisor icon-mini"
            width={200}
            height={80}
            fontSize={14}
            b={830}
            l={910}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 155,
                height: 65,
                width: 120,
                borderRight: "4px solid #aab2ba",
                borderBottom: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  fontSize: 9,
                  position: "absolute",
                  bottom: -50,
                  left: "100%",
                }}
              >
                <div>Высшее профессиональное</div>
                <div>образование, стаж работы</div>
                <div>инженерно - техническим работником</div>
                <div>не менее 5 лет</div>
              </div>
            </div>
          </Card>

          <Card
            id={226}
            addClass="end icon-other icon-mini"
            width={215}
            height={90}
            fontSize={14}
            b={630}
            l={910}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 135,
                height: 105,
                width: 160,
                borderRight: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -23,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={225}
            addClass="end icon-other icon-mini"
            width={215}
            height={90}
            fontSize={14}
            b={630}
            l={1130}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 135,
                height: 50,
                width: 223,
                borderRight: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -23,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={224}
            addClass="end icon-other icon-mini"
            width={215}
            height={90}
            fontSize={14}
            b={630}
            l={1350}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 135,
                height: 50,
                width: 400,
                borderRight: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -23,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={227}
            addClass="end icon-other icon-mini"
            width={215}
            height={70}
            fontSize={14}
            b={770}
            l={1130}
          >
            <div
              className="node__arrow"
              style={{
                top: 40,
                left: -800,
                height: 250,
                width: 800,
                borderLeft: "4px solid #5B646E",
                borderTop: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  top: -12,
                  right: -2,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={129}
            addClass="end icon-other icon-mini"
            width={215}
            height={70}
            fontSize={14}
            b={900}
            l={1130}
          ></Card>
        </div>
        <SchemFooter />
      </>
    </MainLayout>
  );
};

// [91, 92, 93, 94, 95, 96, 101, 97, 98, 102, 99, 100, 103, 104, 105, 106, 108, 107, 109, 26, 226, 225, 224, 227, 129] - Котлотурбинный цех электростанции

export default BoilerPipes;
