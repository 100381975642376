import React from "react";
import { Link } from "react-router-dom";

type Props = {
  onClick: () => void;
  url?: string;
};

const ButtonBack = ({ onClick, url }: Props) => {
  return (
    <>
      {url ? (
        <Link to={url}>
          <button
            className="button-back"
            onClick={() => {
              onClick();
            }}
          >
            <img src="./arrow_back.svg" alt="" />
          </button>
        </Link>
      ) : (
        <button
          className="button-back"
          onClick={() => {
            onClick();
          }}
        >
          <img src="./arrow_back.svg" alt="" />
        </button>
      )}
    </>
  );
};

export default ButtonBack;
