import React, { useEffect } from "react";
import "./style.scss";

type Props = {
  children?: JSX.Element;
  background?: boolean;
};

const MainLayout = ({ children, background = true }: Props) => {
  return (
    <div className="main">
      {background ? (
        <>
          <img src="./images/bg_top.png" alt="" className="main__bg-top" />
          <img
            src="./images/bg_bottom.png"
            alt=""
            className="main__bg-bottom"
          />
        </>
      ) : null}
      <div className="content">{children}</div>
    </div>
  );
};

export default MainLayout;
