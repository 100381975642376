import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import PositionFooter from "./components/PositionFooter";

import "./styles.scss";

const Position22 = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Заместитель технического директора (главного инженера) тепловых
            сетей по эксплуатации
          </div>
        </div>
        <div className="node__list" style={{ height: 800, width: 1900 }}>
          <Card
            id={258}
            addClass="corporate-center"
            b={640}
            l={1250}
            width={350}
            height={150}
          >
            <div
              className="node__arrow"
              style={{
                top: 75,
                left: -90,
                height: 235,
                width: 90,
                borderLeft: "4px solid #AAB2BA",
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -11,
                  left: 70,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 310,
                left: -196,
                width: 110,
                borderTop: "4px solid #AAB2BA",
              }}
            ></div>
          </Card>
          <Card id={196} addClass="sales" b={530} l={1250} width={300}>
            <div
              className="node__arrow"
              style={{
                top: 75,
                left: -60,
                height: 145,
                width: 80,
                borderLeft: "4px solid #AAB2BA",
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -11,
                  left: 40,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 216,
                left: -170,
                width: 110,
                borderTop: "4px solid #AAB2BA",
              }}
            ></div>
          </Card>

          <Card id={218} addClass="branch" b={420} l={1250} width={300}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 250,
                height: 40,
                width: 250,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={178} addClass="branch" b={470} l={750} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -2,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={179} addClass="branch" b={590} l={750} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -2,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 35,
                left: -90,
                height: 225,
                width: 90,
                borderLeft: "4px solid #AAB2BA",
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -11,
                  left: 70,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 257,
                left: -90,
                width: 110,
                borderTop: "4px solid #AAB2BA",
              }}
            ></div>
          </Card>

          <Card id={189} addClass="heating-network" b={350} l={750} width={350}>
            <div
              className="node__arrow"
              style={{
                top: 90,
                left: "50%",
                height: 145,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket down"
                style={{ width: 785 }}
              ></div>
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 209,
                left: 50,
                height: 28,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 209,
                left: 300,
                height: 28,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 75,
                right: -290,
                height: 45,
                width: 290,
                borderRight: "4px solid #AAB2BA",
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -11,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={175} addClass="branch" b={430} l={350} width={300}>
            <div
              className="node__arrow"
              style={{
                top: -10,
                left: 263,
                height: 228,
                width: 40,
                borderLeft: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
                transform: "rotate(90deg) scaleX(-1)",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: 85,
                left: 123,
                height: 148,
                width: 80,
                borderLeft: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={55}
            addClass="heating-network"
            b={230}
            l={550}
            width={350}
          ></Card>
          <Card
            id={54}
            addClass="heating-network"
            b={230}
            l={960}
            width={350}
          ></Card>
          <Card
            id={171}
            addClass="heating-network"
            b={230}
            l={1350}
            width={350}
          ></Card>

          <Card
            id={209}
            addClass="heating-network"
            b={100}
            l={550}
            width={350}
          ></Card>
          <Card
            id={52}
            addClass="heating-network"
            b={100}
            l={960}
            width={350}
          ></Card>
        </div>
        <PositionFooter />
      </div>
    </MainLayout>
  );
};

// [8, 15, 22, 52, 170, 214, 209, 55, 29, 175] - Заместитель технического директора (главного инженера) тепловых сетей по эксплуатации (key=08)

export default Position22;
