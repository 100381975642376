import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import PositionFooter from "./components/PositionFooter";

const Position14 = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">Начальник смены электростанции</div>
        </div>
        <div className="node__list" style={{ height: 700, width: 1550 }}>
          <Card
            id={77}
            addClass="power-station"
            b={100}
            l={250}
            width={400}
          ></Card>

          <Card
            id={85}
            addClass="power-station"
            b={100}
            l={700}
            width={400}
          ></Card>

          <Card
            id={105}
            addClass="power-station"
            b={100}
            l={1150}
            width={400}
          ></Card>

          <Card
            id={167}
            addClass="power-station"
            b={230}
            l={770}
            width={250}
            height={130}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: "50%",
                height: 29,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket down"
                style={{ width: 1330 }}
              ></div>
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                bottom: "100%",
                left: "50%",
                height: 29,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket"
                style={{ width: 1400 }}
              ></div>
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={244}
            addClass="power-station"
            b={400}
            l={220}
            width={220}
            height={140}
          ></Card>
          <Card
            id={26}
            addClass="power-station"
            b={400}
            l={450}
            width={220}
            height={140}
          ></Card>
          <Card
            id={80}
            addClass="power-station"
            b={400}
            l={680}
            width={220}
            height={140}
          ></Card>
          <Card
            id={109}
            addClass="power-station"
            b={400}
            l={910}
            width={220}
            height={140}
          ></Card>
          <Card
            id={88}
            addClass="power-station"
            b={400}
            l={1140}
            width={220}
            height={140}
          ></Card>
          <Card
            id={259}
            addClass="heating-network"
            b={400}
            l={1370}
            width={220}
            height={140}
          ></Card>

          <Card id={220} addClass="power-station" b={560} l={130} width={400}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 20,
                height: 280,
                width: 620,
                borderLeft: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={260} addClass="branch" b={560} l={800} width={400}></Card>

          <Card id={261} addClass="branch" b={560} l={1230} width={400}>
            <div
              className="node__arrow"
              style={{
                top: 100,
                right: 20,
                height: 260,
                width: 620,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket"
                style={{ width: 860, left: 230 }}
              ></div>
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                  left: 618,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
        </div>
        <PositionFooter />
      </div>
    </MainLayout>
  );
};

export default Position14;
