import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import SchemFooter from "./components/SchemFooter";

import "./styles.scss";

const Chemical = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Химическая служба тепловых сетей
          </div>
        </div>
        <div className="node__list" style={{ height: 740, width: 1900 }}>
          <Card id={6} addClass="end icon-other" b={550} l={1200}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 225,
                height: 115,
                width: 400,
                borderRight: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={7} addClass="end icon-other" b={550} l={700}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 100,
                borderLeft: "4px solid #5B646E",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={8} addClass="end icon-other" b={550} l={200}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 115,
                width: 400,
                borderLeft: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 20, left: 10 }}
              >
                <div>3 года - высшее образование</div>
              </div>
            </div>
          </Card>
          <Card id={5} addClass="icon-supervisor" b={400} l={700}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 90,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 90,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 20, left: 10 }}
              >
                <div>3 года - высшее образование</div>
              </div>
            </div>
          </Card>
          <Card id={4} addClass="icon-supervisor" b={250} l={950}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 100,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 20, left: 10 }}
              >
                <div>Стаж не менее 3 лет</div>
              </div>
            </div>
          </Card>
          <Card id={3} addClass="icon-supervisor" b={250} l={450}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 100,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 20, right: 10 }}
              >
                <div>Стаж не менее 2 лет</div>
              </div>
            </div>
          </Card>
          <Card id={2} addClass="start icon-start" b={100} l={700} />
        </div>
        <SchemFooter />
      </div>
    </MainLayout>
  );
};

export default Chemical;
