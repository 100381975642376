import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import MainLayout from "../../components/MainLayout/MainLayout";
import Card from "../components/Card";
import SchemFooter from "../components/SchemFooter";

const ChemicalDepartment = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">Химический цех электростанции</div>
        </div>

        <div className="node__list" style={{ height: 850, width: 1900 }}>
          <Card
            id={56}
            width={225}
            height={90}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={350}
          ></Card>
          <Card
            id={57}
            width={225}
            height={90}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={600}
          ></Card>
          <Card
            id={58}
            width={225}
            height={90}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={850}
          ></Card>
          <Card
            id={59}
            width={225}
            height={90}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={1100}
          ></Card>
          <Card
            id={60}
            width={225}
            height={90}
            fontSize={14}
            addClass="start icon-start icon-mini"
            b={100}
            l={1350}
          ></Card>

          <Card
            id={61}
            addClass="start2 icon-start icon-mini"
            width={225}
            height={90}
            fontSize={14}
            b={250}
            l={850}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: "50%",
                height: 50,
                width: 10,
                borderRight: "4px solid #d73b13",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#d73b13"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 10, right: 20 }}
              >
                <div>
                  <p style={{ fontSize: 10 }}>
                    С любой должности через стажировку и дублирование <br />
                    стаж при 1 год - высшее профессиональное образование,
                    <br />
                    стаж при 3 года - среднее профессиональное образование
                  </p>
                </div>
              </div>
              <div
                className="node__arrow-brecket down"
                style={{
                  left: 8,
                  top: "100%",
                  width: 1250,
                  borderColor: "#D73B13",
                }}
              ></div>
            </div>
          </Card>

          <Card
            id={62}
            addClass="supervisor icon-supervisor icon-mini"
            width={225}
            height={90}
            fontSize={14}
            b={250}
            l={1100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 175,
                height: 50,
                width: 10,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 20, left: 20 }}
              >
                <div>
                  <p style={{ fontSize: 10 }}>
                    3 года - высшее профессиональное образование
                  </p>
                </div>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: -26,
                left: -142,
                height: 50,
                width: 185,
                borderRight: "4px solid #aab2ba",
                borderTop: "4px solid #aab2ba",
              }}
            ></div>
          </Card>

          <Card
            id={148}
            addClass="supervisor icon-supervisor icon-mini"
            width={225}
            height={90}
            fontSize={14}
            b={400}
            l={850}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: "50%",
                height: 115,
                width: 10,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 15, left: 20 }}
              >
                <div>
                  <p style={{ fontSize: 10 }}>
                    2 года - высшее профессиональное образование
                  </p>
                </div>
              </div>
            </div>
          </Card>

          <Card
            id={226}
            addClass="supervisor icon-supervisor icon-mini"
            width={225}
            height={90}
            fontSize={14}
            b={400}
            l={600}
          >
            <div
              className="node__arrow"
              style={{
                top: 45,
                right: -58,
                height: 115,
                width: 50,
                borderTop: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: -9,
                  top: -12,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            addClass="end icon-other icon-mini"
            id={229}
            width={225}
            height={90}
            fontSize={14}
            b={400}
            l={1100}
          >
            <div
              className="node__arrow"
              style={{
                top: 45,
                left: -96,
                height: 115,
                width: 90,
                borderTop: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: 79,
                  top: -12,
                  transform: "rotate(0deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={26}
            addClass="supervisor icon-supervisor icon-mini"
            width={225}
            height={90}
            fontSize={14}
            b={550}
            l={600}
          >
            <div
              className="node__arrow"
              style={{
                top: 45,
                right: -58,
                height: 115,
                width: 50,
                borderTop: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: -9,
                  top: -12,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 113,
                height: 115,
                width: 10,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={65}
            addClass="supervisor icon-supervisor icon-mini"
            width={225}
            height={90}
            fontSize={12}
            b={550}
            l={350}
          >
            <div
              className="node__arrow"
              style={{
                top: -48,
                right: -313,
                height: 45,
                width: 400,
                borderRight: "4px solid #aab2ba",
                borderTop: "4px solid #aab2ba",
                borderLeft: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: -12,
                  top: 24,
                  transform: "rotate(90deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={67}
            addClass="supervisor icon-supervisor icon-mini"
            width={225}
            height={90}
            fontSize={14}
            b={550}
            l={850}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: "50%",
                height: 115,
                width: 10,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 20, left: 20 }}
              >
                <div>
                  <p style={{ fontSize: 10 }}>
                    2-3 года - высшее профессиональное образование
                  </p>
                </div>
              </div>
            </div>
          </Card>

          <Card
            id={64}
            addClass="supervisor icon-supervisor icon-mini"
            width={225}
            height={90}
            fontSize={14}
            b={730}
            l={850}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: "50%",
                height: 115,
                width: 10,
                borderRight: "4px solid #aab2ba",
                // borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", top: 20, left: 20 }}
              >
                <div>
                  <p style={{ fontSize: 10 }}>
                    5 лет - высшее <br />
                    профессиональное <br />
                    образование
                  </p>
                </div>
              </div>
            </div>
          </Card>

          <Card
            id={205}
            addClass="supervisor icon-supervisor icon-mini"
            width={225}
            height={90}
            fontSize={14}
            b={650}
            l={1100}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 110,
                height: 40,
                width: 200,
                borderRight: "4px solid #aab2ba",
                borderBottom: "4px solid #aab2ba",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#aab2ba"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            addClass="end icon-other icon-mini"
            id={63}
            width={225}
            height={90}
            fontSize={14}
            b={650}
            l={1350}
          >
            <div
              className="node__arrow"
              style={{
                top: 84,
                right: 155,
                height: 60,
                width: 480,
                borderRight: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 105,
                height: 190,
                width: 300,
                borderRight: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
        </div>
        <SchemFooter />
      </>
    </MainLayout>
  );
};

export default ChemicalDepartment;
