import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Admin from "./components/Admin/Admin";
import Developing from "./components/Developing/Developing";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HeatAndPower from "./components/HeatAndPower/HeatAndPower";
import HeatingNetwork from "./components/HeatingNetwork/HeatingNetwork";
import Panorama from "./components/Panorama/Panorama";
import Position from "./components/Position/Position";
import Structure from "./components/Structure/Structure";
import Tree from "./components/Tree/Tree";
import APCSService from "./schemes/APCSService";
import BoilersDistrict from "./schemes/BoilersDistrict";
import Chemical from "./schemes/Chemical";
import AutoControlSystem from "./schemes/Chp/AutoControlSystem";
import BoilerPipes from "./schemes/Chp/BoilerPipes";
import CentralPowerPlantRepairService from "./schemes/Chp/CentralPowerPlantRepairService";
import ChemicalDepartment from "./schemes/Chp/ChemicalDepartment";
import ElectricalWorkshop from "./schemes/Chp/ElectricalWorkshop";
import Electrical from "./schemes/Electrical";
import HeatingNetworks from "./schemes/HeatingNetworks";
import PositionNew from "./schemes/Position";
import Position01 from "./schemes/Position_01";
import Position02 from "./schemes/Position_02";
import Position03 from "./schemes/Position_03";
import Position04 from "./schemes/Position_04";
import Position05 from "./schemes/Position_05";
import Position06 from "./schemes/Position_06";
import Position07 from "./schemes/Position_07";
import Position08 from "./schemes/Position_08";
import Position09 from "./schemes/Position_09";
import Position10 from "./schemes/Position_10";
import Position11 from "./schemes/Position_11";
import Position12 from "./schemes/Position_12";
import Position13 from "./schemes/Position_13";
import Position14 from "./schemes/Position_14";
import Position15 from "./schemes/Position_15";
import Position16 from "./schemes/Position_16";
import Position17 from "./schemes/Position_17";
import Position18 from "./schemes/Position_18";
import Position19 from "./schemes/Position_19";
import Position20 from "./schemes/Position_20";
import Position21 from "./schemes/Position_21";
import Position22 from "./schemes/Position_22";

function App() {
  const [structure, setStructure] = useState<string>("");
  const [department, setDepartment] = useState<string>("1");
  const [id, setId] = useState<string>("0");

  const [trajectoryId, setTrajectoryId] = useState<string>("0");

  const [positionId, setPositionId] = useState<string>("0");

  // const handleStructure = (val: string) => {
  //   setStructure(val);
  // };

  const handleDepartment = (val: string) => {
    setDepartment(val);
  };

  const handleId = (id: string) => {
    setId(id);
  };

  const handletrajectoryId = (id: string) => {
    setTrajectoryId(id);
  };

  const handlePositionId = (id: string) => {
    setPositionId(id);
  };

  useEffect(() => {
    // console.log(structure);
    // if (department) {
    //   window.location.href = "/structure";
    // }
  }, [structure]);

  return (
    <>
      <Router>
        <Header
          handleDepartment={handleDepartment}
          handletrajectoryId={handletrajectoryId}
          handlePositionId={handlePositionId}
        />
        <Routes>
          {/* <Route
            path="/"
            element={<Main handleStructure={handleStructure} />}
          /> */}
          <Route path="/tree" element={<Tree />} />
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/ts"
            element={<HeatingNetwork handleDepartment={handleDepartment} />}
          />
          <Route
            path="/chp"
            element={<HeatAndPower handleDepartment={handleDepartment} />}
          />
          <Route
            path="/structure"
            element={
              <Structure
                handleId={handleId}
                block={department}
                trajectoryId={trajectoryId}
                structure={structure}
              />
            }
          />
          <Route
            path="/position"
            element={
              <Position
                department={department}
                id={positionId}
                structure={structure}
              />
            }
          />
          <Route path="/pos" element={<PositionNew />} />

          <Route path="/electrical" element={<Electrical />} />
          <Route path="/chemical" element={<Chemical />} />
          <Route path="/boilersdistrict" element={<BoilersDistrict />} />
          <Route path="/apcsservice" element={<APCSService />} />
          <Route path="/heatingnetworks" element={<HeatingNetworks />} />

          <Route path="/boilerpipes" element={<BoilerPipes />} />
          <Route path="/autocontrolsystem" element={<AutoControlSystem />} />
          <Route path="/chemicaldepartment" element={<ChemicalDepartment />} />
          <Route path="/electricalworkshop" element={<ElectricalWorkshop />} />
          <Route
            path="/centralpowerplantrepairservice"
            element={<CentralPowerPlantRepairService />}
          />

          <Route path="/" element={<Panorama />} />
          <Route path="/position_01" element={<Position01 />} />
          <Route path="/position_02" element={<Position02 />} />
          <Route path="/position_03" element={<Position03 />} />
          <Route path="/position_04" element={<Position04 />} />
          <Route path="/position_05" element={<Position05 />} />
          <Route path="/position_06" element={<Position06 />} />
          <Route path="/position_07" element={<Position07 />} />
          <Route path="/position_08" element={<Position08 />} />
          <Route path="/position_09" element={<Position09 />} />
          <Route path="/position_10" element={<Position10 />} />
          <Route path="/position_11" element={<Position11 />} />
          <Route path="/position_12" element={<Position12 />} />
          <Route path="/position_13" element={<Position13 />} />
          <Route path="/position_14" element={<Position14 />} />
          <Route path="/position_15" element={<Position15 />} />
          <Route path="/position_16" element={<Position16 />} />
          <Route path="/position_17" element={<Position17 />} />
          <Route path="/position_18" element={<Position18 />} />
          <Route path="/position_19" element={<Position19 />} />
          <Route path="/position_20" element={<Position20 />} />
          <Route path="/position_21" element={<Position21 />} />
          <Route path="/position_22" element={<Position22 />} />

          <Route path="/developing" element={<Developing />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;

// style={{
//   cursor: "pointer",
// }}
// onClick={() => {
//   navigation("/position", { state: { id: 26 } });
// }}
