import React from "react";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

const SchemFooter = () => {
  const navigate = useNavigate();

  return (
    <div className="schem__container">
      <div className="schem__container-left">
        <div
          className="schem__btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <div className="schem__btn-icon">
            <svg
              width="32"
              height="22"
              viewBox="0 0 32 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.34311 9.13953C6.43311 9.19953 6.57311 9.17957 6.68311 9.17957C10.6431 9.13957 14.8131 9.17957 18.7831 9.17957C21.8931 9.17957 25.0031 9.17957 28.1131 9.17957C29.1031 9.17957 30.2931 9.23956 31.2631 9.16956L31.2531 12.7996C30.3031 12.7296 29.1331 12.7896 28.1431 12.7896H7.1131C6.9831 12.7896 6.8531 12.7896 6.7131 12.7896C6.6031 12.7896 6.44311 12.7695 6.34311 12.8195C7.81311 14.5895 9.35311 16.5395 10.8131 18.3395L12.6031 20.5596C12.7631 20.7396 12.9131 20.9495 13.0531 21.1195C13.2031 21.2895 13.4031 21.5195 13.5031 21.6895C12.5631 21.7395 11.5031 21.6895 10.5531 21.6895C8.9231 21.6895 9.36313 21.9296 8.45312 20.8096C7.20312 19.2696 5.7331 17.4096 4.4631 15.9096C4.0131 15.3896 3.57312 14.8295 3.13312 14.2795L0.453125 10.9795L4.44312 6.06946C4.68312 5.78946 4.8931 5.51953 5.1131 5.25953C5.3431 4.97953 5.5531 4.70946 5.7731 4.43946C6.7631 3.27946 7.77312 1.94955 8.76312 0.779546C9.30312 0.139546 8.92312 0.279546 10.5131 0.279546C11.4731 0.279546 12.5531 0.229546 13.5031 0.279546C13.4031 0.459546 11.5831 2.66955 11.2931 3.02955L9.94312 4.69947C9.64312 5.07947 9.3631 5.43957 9.0531 5.79957C8.1531 6.87957 7.24311 8.06953 6.34311 9.13953Z"
                fill="#1E1E1D"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.34375 12.8196C6.44375 12.7696 6.60375 12.7897 6.71375 12.7897C6.85375 12.7897 6.98374 12.7897 7.11374 12.7897H28.1438C29.1338 12.7897 30.3038 12.7297 31.2538 12.7997L31.2638 9.16968C30.2938 9.23968 29.1037 9.17969 28.1137 9.17969C25.0037 9.17969 21.8938 9.17969 18.7838 9.17969C14.8138 9.17969 10.6437 9.13969 6.68375 9.17969C6.57375 9.17969 6.43375 9.19965 6.34375 9.13965H31.2737L31.2938 12.8196H6.34375Z"
                fill="#1E1E1D"
              />
            </svg>
          </div>
          <div className="schem__btn-text">НАЗАД</div>
        </div>
        <div className="schem__list">
          <div className="schem__list-item start">
            <div className="schem__item-icon">
              <img src="/images/schemes/start.svg" alt="" />
            </div>
            <div className="schem__item-text">
              Стартовая/начальная должность
            </div>
          </div>
          <div className="schem__list-item worker">
            <div className="schem__item-icon">
              <img src="/images/schemes/worker.svg" alt="" />
            </div>
            <div className="schem__item-text">Рабочий/оперативный персонал</div>
          </div>
          <div className="schem__list-item supervisor">
            <div className="schem__item-icon">
              <img src="/images/schemes/supervisor.svg" alt="" />
            </div>
            <div className="schem__item-text">ИТР/руководители</div>
          </div>
          <div className="schem__list-item other">
            <div className="schem__item-icon">
              <img src="/images/schemes/other.svg" alt="" />
            </div>
            <div className="schem__item-text">
              Должность в другом подразделении
            </div>
          </div>
        </div>
      </div>
      <div className="schem__list">
        <div className="schem__list-arrow">
          <img src="/images/arrow_1.svg" alt="" />
        </div>
        <div className="schem__list-arrow">
          <img src="/images/arrow_2.svg" alt="" />
        </div>
        <div className="schem__list-arrow">
          <img src="/images/arrow_3.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default SchemFooter;
