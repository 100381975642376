import { useEffect, useRef, useState } from "react";
import { Handle, Position } from "reactflow";

type Props = {
  data: any;
  id: string;
  xPos: number;
  yPos: number;
};

const FoureOutputInputNode = ({ data, id, xPos, yPos }: Props) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (nodeRef.current) {
      // const nodeWidth = nodeRef.current.offsetWidth;

      // const nodeHeight = nodeRef.current.offsetHeight;

      // data.xPos = xPos + (400 / 2 - nodeWidth / 2);
      // data.yPos = yPos + (100 / 2 - nodeHeight / 2);
    }
  }, []);

  return (
    <div ref={nodeRef} className={"card card-test " + data.class}>
      <span>{data.label}</span>
      <Handle type="source" position={Position.Top} id="Top" />
      {/* <Handle type="source" position={Position.Right} id="Right" /> */}
      {/* <Handle type="source" position={Position.Left} id="Left" /> */}
      {/* <Handle type="source" position={Position.Bottom} id="Bottom" /> */}
      {/* <Handle type="target" position={Position.Top} id="Top" /> */}
      {/* <Handle type="target" position={Position.Right} id="Right" /> */}
      {/* <Handle type="target" position={Position.Left} id="Left" /> */}
      <Handle type="target" position={Position.Bottom} id="Bottom" />
    </div>
  );
};

export default FoureOutputInputNode;
