import React from "react";
import ButtonBack from "../ButtonBack/ButtonBack";
import MainLayout from "../MainLayout/MainLayout";

type Props = {
  handleDepartment?: (val: string) => void;
};

const Admin = ({ handleDepartment }: Props) => {
  return (
    <MainLayout>
      <div className="container">
        <div className="back-wrpper">
          <ButtonBack onClick={() => {}} url="/" />
        </div>
        <div className="title">Администратинвый корпус филиала</div>
        <div className="heating-network__wrapper">
          <div className="wrapper">
            <img
              className="heating-network__bg"
              src="./images/admin/admin-bg.svg"
              alt=""
            />
            <img
              src="./images/admin/circles/apc_system.svg"
              alt=""
              className="heating-network__circle left top"
            />
            <img
              src="./images/admin/circles/repair_system.svg"
              alt=""
              className="heating-network__circle right top"
            />
            <img
              src="./images/admin/circles/vehicle_operation_management.svg"
              alt=""
              className="heating-network__circle right bottom"
            />
            <img
              src="./images/admin/circles/production_technical_management.svg"
              alt=""
              className="heating-network__circle left bottom"
            />
            <img
              src="./images/admin/circles/tb_and_oz.svg"
              alt=""
              className="heating-network__circle left half-top"
            />
            <img
              src="./images/admin/circles/ots.svg"
              alt=""
              className="heating-network__circle left half-bottom"
            />
            <img
              src="./images/admin/circles/up.svg"
              alt=""
              className="heating-network__circle right half-bottom"
            />
            <img
              src="./images/admin/circles/technical_inspection_department.svg"
              alt=""
              className="heating-network__circle right half-top"
            />
            {/* <img
              src="./images/heating-network/circles/apc_system.svg"
              alt=""
              className="heating-network__circle center right"
            />
            <img
              src="./images/heating-network/circles/boiler.svg"
              alt=""
              className="heating-network__circle left center"
            />
            <img
              src="./images/heating-network/circles/departments.svg"
              alt=""
              className="heating-network__circle left bottom"
            />
            <img
              src="./images/admin/circles/apc_system.svg"
              alt=""
              className="heating-network__circle left top"
            />
            <img
              src="./images/heating-network/circles/heating_networks.svg"
              alt=""
              className="heating-network__circle bottom right"
            />
            <img
              src="./images/heating-network/circles/chemical_service.svg"
              alt=""
              className="heating-network__circle top right"
            /> */}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Admin;
