import React, { useState } from "react";

type Item = {
  id: string;
  name: string;
  preview: string | null;
  object: string;
};

type Props = {
  data: Item[] | undefined;
  value: Item;
  disabled?: boolean;
  handleDropdownValue: (data: Item) => void;
  title?: string;
};

const Dropdown = ({
  title,
  data,
  value,
  handleDropdownValue,
  disabled = false,
}: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <div
      className={isActive ? "dropdown active" : "dropdown"}
      data-disabled={disabled}
    >
      <div
        className="dropdown__head"
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <div>
          <div className="dropdown__head-title">{title}</div>
          <div className="dropdown__head-output">
            {value.name} {value.object && `(${value.object})`}
          </div>
        </div>
        {value.preview && (
          <div className="dropdown__head-img">
            <img src={value.preview} alt="" />
          </div>
        )}
      </div>
      <div className="dropdown__list">
        {data ? (
          data.map((item: Item, index) => {
            return (
              <div
                key={item.id + "-" + index}
                className="dropdown__list-item"
                onClick={() => {
                  handleDropdownValue(item);
                  setIsActive(false);
                }}
              >
                {item.name} {item.object && `(${item.object})`}
              </div>
            );
          })
        ) : (
          <div className="dropdown__list-item empty">должность не найдена</div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
