import React, { useEffect, useState } from "react";
import ButtonBack from "../ButtonBack/ButtonBack";
import MainLayout from "../MainLayout/MainLayout";

import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";

import "./style.scss";

export const url = "https://api-tplus.tw1.ru/api/v1";

type Props = {
  department: string;
  id: string;
  structure: string;
};

type RequestData = {
  name?: string;
  preview?: string;
  category?: string;
  education?: string;
  startPosition?: string;
  endPosition?: string;
  knowledge?: string;
  subdivision?: string;
  experience?: string;
} | null;

const Position = ({ department, id, structure }: Props) => {
  const [data, setData] = useState<RequestData>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [params, setParams] = useState<string | null>(null);

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    setParams(location.state?.id);
    console.log("new position");
  }, [location.state?.id]);

  useEffect(() => {
    if (params) {
      axios
        .get(url + "/position/one-by-id", {
          params: {
            id: params,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.model) {
            setData(response.data.model);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [params]);

  useEffect(() => {
    setIsLoading(true);

    if (!params) {
      axios
        .get(url + "/position/one", {
          params: {
            keyPosition: id,
            keySubdivision: department,
            keyObject: structure,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.model) {
            setData(response.data.model);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [id, department, structure]);

  useEffect(() => {
    console.log("data: ", data);
  }, [data]);

  return (
    <MainLayout>
      {isLoading ? (
        <div className="container">
          <ButtonBack
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="position__info-text center">Загрузка...</div>
        </div>
      ) : (
        <>
          {data ? (
            <>
              <div className="container">
                <ButtonBack
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <div className="position">
                  <div className="position__img">
                    <img src={data.preview} alt="" />
                  </div>
                  <div className="position__info">
                    <div className="position__info-title">{data.name}</div>
                    {data.subdivision ? (
                      <div className="position__info-block">
                        <div className="position__info-subtitle">
                          Структурное подразделение
                        </div>
                        <div className="position__info-text">
                          {data.subdivision}
                        </div>
                      </div>
                    ) : null}
                    {data.category ? (
                      <div className="position__info-block">
                        <div className="position__info-subtitle">
                          Категория персонала
                        </div>
                        <div className="position__info-text">
                          {data.category}
                        </div>
                      </div>
                    ) : null}
                    {data.education ? (
                      <div className="position__info-block">
                        <div className="position__info-subtitle">
                          Требования к образованию
                        </div>
                        <div className="position__info-text">
                          {data.education}
                        </div>
                      </div>
                    ) : null}

                    {data.experience ? (
                      <div className="position__info-block">
                        <div className="position__info-subtitle">
                          Необходимый стаж работы
                        </div>
                        <div className="position__info-text">
                          {data.experience}
                        </div>
                      </div>
                    ) : null}
                    {data.startPosition ? (
                      <div className="position__info-block">
                        <div className="position__info-subtitle">
                          С какой должности назначается
                        </div>
                        <div className="position__info-text">
                          {data.startPosition}
                        </div>
                      </div>
                    ) : null}
                    {data.endPosition ? (
                      <div className="position__info-block">
                        <div className="position__info-subtitle">
                          На какую должность назначается
                        </div>
                        <div className="position__info-text">
                          {data.endPosition}
                        </div>
                      </div>
                    ) : null}
                    {data.knowledge ? (
                      <div className="position__info-block">
                        <div className="position__info-subtitle">
                          Дополнительные знания
                        </div>
                        <div className="position__info-text">
                          {data.knowledge}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="container">
              <ButtonBack
                onClick={() => {
                  navigate(-1);
                }}
              />
              <div className="position__info-title center">
                Должность не найдена
              </div>
            </div>
          )}
        </>
      )}
    </MainLayout>
  );
};

export default Position;
