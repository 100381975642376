import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import PositionFooter from "./components/PositionFooter";

import "./styles.scss";

const Position07 = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Заместитель технического директора (главного инженера) тепловых
            сетей по обеспечению технического состояния
          </div>
        </div>
        <div className="node__list" style={{ height: 750, width: 1900 }}>
          <Card id={213} addClass="branch" b={600} l={1200}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 350,
                height: 180,
                width: 150,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={29} addClass="heating-network" b={500} l={700}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -2,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={212}
            addClass="heating-network"
            b={350}
            l={700}
            height={110}
          >
            <div
              className="node__arrow"
              style={{
                top: 108,
                left: 220,
                height: 25,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket down"
                style={{ width: 1480 }}
              ></div>
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={209} addClass="heating-network" b={220} l={200}></Card>
          <Card id={53} addClass="heating-network" b={220} l={700}></Card>
          <Card id={52} addClass="heating-network" b={220} l={1200}></Card>

          <Card id={208} addClass="heating-network" b={100} l={200}></Card>
          <Card id={210} addClass="heating-network" b={100} l={700}></Card>
          <Card id={211} addClass="heating-network" b={100} l={1200}></Card>
        </div>
        <PositionFooter />
      </div>
    </MainLayout>
  );
};

// [213, 29, 212, 209, 53, 52, 208, 210, 211] - Заместитель технического директора (главного инженера) тепловых сетей по обеспечению технического состояния (key=07)

export default Position07;
