import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { url } from "../Position/Position";

const defaultTrajectory = {
  id: "0",
  name: "Выберите должность",
  preview: null,
  object: "",
};

type Props = {
  handleDepartment: (val: string) => void;
  handletrajectoryId: (id: string) => void;
  handlePositionId: (id: string) => void;
};

type ResponseData = {
  id: string;
  name: string;
  preview: string | null;
  object: string;
};

const Header = ({
  handleDepartment,
  handlePositionId,
}: // handletrajectoryId,
Props) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [outputStart, setOutputStart] =
    useState<ResponseData>(defaultTrajectory);
  const [outputEnd, setOutputEnd] = useState<ResponseData>(defaultTrajectory);
  const [dataStart, setDataStart] = useState<ResponseData[]>();
  const [dataEnd, setDataEnd] = useState<ResponseData[]>();
  const [isLoadingDataStart, setIsLoadingDataStart] = useState<boolean>(true);
  const [isLoadingDataEnd, setIsLoadingDataEnd] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [schemeKey, setSchemeKey] = useState<string>("");

  const navigate = useNavigate();

  const handleDropdownValueStart = (value: ResponseData) => {
    if (isLoadingDataEnd) {
      return;
    }

    setIsLoadingDataEnd(true);
    setOutputStart(value);
    setDataEnd(undefined);
    setOutputEnd(defaultTrajectory);

    axios
      .get(url + "/construction/create-path", {
        params: {
          idStartPosition: value.id,
        },
      })
      .then((response) => {
        if (response.data.model.length > 0) {
          setDataEnd(
            response.data.model.filter(
              (data: { id: string }) => data.id !== value.id.toString()
            )
          );
        } else {
          setDataEnd(undefined);
        }
        setOutputEnd(defaultTrajectory);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoadingDataEnd(false);
      });
  };

  const handleDropdownValueEnd = (value: ResponseData) => {
    setOutputEnd(value);
  };

  const getSchema = () => {
    axios
      .get(url + "/construction/create-path", {
        params: {
          idStartPosition: outputStart.id,
          idEndPosition: outputEnd.id,
        },
      })
      .then((res) => {
        // console.log("res: ", res);
        if (res.data.model[0]) {
          setSchemeKey(res.data.model[0].key);
        } else {
          setSchemeKey("0");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const redirectToScheme = (key: string) => {
    navigate(`/${key}`);
    setIsShowModal(false);
  };

  useEffect(() => {
    axios
      .get(url + "/position/all")
      .then((response) => {
        const arr = response.data.model;

        setDataStart(
          arr.sort((a: ResponseData, b: ResponseData) =>
            a.name > b.name ? 1 : -1
          )
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoadingDataStart(false);
      });
  }, []);

  useEffect(() => {
    getSchema();
  }, [outputEnd]);

  useEffect(() => {
    if (schemeKey !== "0" && schemeKey) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [schemeKey]);

  return (
    <>
      <div className="header">
        <div className="container header__container">
          <div className="header__left">
            <div className="header__logo">
              <Link to="/">
                <img
                  src="./images/logo.png"
                  alt=""
                  className="header__logo-img"
                />
              </Link>
            </div>
            <div className="heaer__text">
              <div className="header__text-title">8 (800) 700-06-06</div>
              <div className="header__text-subtitle">Наш телефон</div>
            </div>
          </div>
          <div className="header__right">
            <div className="heaer__text">
              <div className="header__text-title">
                Хотите поменять должность?
              </div>
              <div className="header__text-subtitle">
                Постройте индивидуальную карьерную траекторию.
              </div>
            </div>
            <Button
              title="Построить"
              onClick={() => {
                setIsShowModal(true);
              }}
            />
          </div>
        </div>
      </div>
      {isShowModal ? (
        <div
          className="popup"
          onClick={(e) => {
            if ((e.target as Element).classList.contains("popup")) {
              setIsShowModal(false);
            }
          }}
        >
          <div className="popup__container">
            <div
              className="popup__close"
              onClick={() => setIsShowModal(false)}
            ></div>
            <div className="popup__title">
              Вы можете посмотреть карьерную траекторию до желаемой должности.
            </div>
            <div className="popup__block">
              <Dropdown
                title="Стартовая должность:"
                data={dataStart}
                handleDropdownValue={handleDropdownValueStart}
                value={outputStart}
                disabled={isLoadingDataStart || isLoadingDataEnd}
              />
              {Number(outputStart.id) > 0 && (
                <div
                  className="popup__block-link"
                  onClick={() => {
                    handlePositionId(outputStart.id);
                    navigate("/position", { state: { id: outputStart.id } });
                    setIsShowModal(false);
                  }}
                >
                  <span>Перейти к должности</span>
                  <img src="/images/popup-arrow.svg" alt="" />
                </div>
              )}
            </div>
            <div className="popup__block">
              <Dropdown
                title="Желаемоя должность:"
                data={dataEnd}
                handleDropdownValue={handleDropdownValueEnd}
                value={outputEnd}
                disabled={!dataEnd || isLoadingDataEnd}
              />
              {Number(outputEnd.id) > 0 && (
                <div
                  className="popup__block-link"
                  onClick={() => {
                    handlePositionId(outputEnd.id);
                    navigate("/position", { state: { id: outputEnd.id } });
                    setIsShowModal(false);
                  }}
                >
                  <span>Перейти к должности</span>
                  <img src="/images/popup-arrow.svg" alt="" />
                </div>
              )}
            </div>
            <button
              className="popup__btn btn"
              disabled={isDisabled}
              onClick={() => {
                redirectToScheme(schemeKey);
              }}
            >
              Отобразить схему
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Header;
