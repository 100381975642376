import React, { useEffect, useState } from "react";
import ReactFlow, { Controls } from "reactflow";

import MainLayout from "../MainLayout/MainLayout";

import "reactflow/dist/style.css";
import "./style.scss";

import {
  chemicalService,
  electricalService,
  acsService,
  heatingNetworks,
  boilerRoom,
  trajectories,
  chpChemicalService,
  chpElectricalService,
  chpAcsService,
  ahpBoilerTurbine,
  ahpRepair,
  Nodes,
} from "../../structureData";
import ButtonBack from "../ButtonBack/ButtonBack";

type Props = {
  block: string;
  handleId: (id: string) => void;
  trajectoryId?: string;
  structure: string;
};

const Structure = ({ block, handleId, trajectoryId, structure }: Props) => {
  const initialData: Nodes = {
    title: undefined,
    id: undefined,
    Nodes: undefined,
    Edges: undefined,
  };

  const [data, setData] = useState<Nodes>(initialData);

  useEffect(() => {
    setData(heatingNetworks);
  }, []);

  // useEffect(() => {
  //   console.log("block: ", block);
  //   console.log("trajectoryId: ", Number(trajectoryId) - 1);

  //   if (structure === "ts") {
  //     if (block === "1") {
  //       setData(chemicalService);
  //     }

  //     if (block === "2") {
  //       setData(electricalService);
  //     }

  //     if (block === "3") {
  //       setData(acsService);
  //     }

  //     if (block === "4") {
  //       setData(heatingNetworks);
  //     }

  //     if (block === "5") {
  //       setData(boilerRoom);
  //     }
  //   }

  //   if (structure === "chp") {
  //     if (block === "1") {
  //       setData(chpChemicalService);
  //     }
  //     if (block === "2") {
  //       setData(chpElectricalService);
  //     }
  //     if (block === "3") {
  //       setData(chpAcsService);
  //     }
  //     if (block === "4") {
  //       setData(ahpBoilerTurbine);
  //     }
  //     if (block === "5") {
  //       setData(ahpRepair);
  //     }
  //   }

  //   if (block === "trajectories") {
  //     if (trajectoryId) {
  //       setData(trajectories[Number(trajectoryId) - 1]);
  //     }
  //   }
  // }, [block, trajectoryId, structure]);

  useEffect(() => {
    console.log("data: ", data);
  }, [data]);

  return (
    <MainLayout background={false}>
      <>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack onClick={() => {}} url={`/${structure}`} />
          </div>
          {block === "trajectories" ? (
            <>
              <div className="structure__title">{data.title}</div>
              <div className="trajectories__list">
                <div className="structure__list-item">
                  <div className="color green"></div>
                  <div className="text">Филиал</div>
                </div>
                <div className="structure__list-item">
                  <div className="color orange"></div>
                  <div className="text">КЦ</div>
                </div>
                <div className="structure__list-item">
                  <div className="color blue"></div>
                  <div className="text">Станция</div>
                </div>
                <div className="structure__list-item">
                  <div className="color light-green"></div>
                  <div className="text">ТС</div>
                </div>
                <div className="structure__list-item">
                  <div className="color yellow"></div>
                  <div className="text">Сбыт</div>
                </div>
              </div>
            </>
          ) : (
            <div className="two-column">
              <div className="col">
                <div className="structure__title">{data.title}</div>
                <div className="structure-text__list">
                  <div className="structure-text__list-item">
                    ВПО - Высшее профессиональное образование
                  </div>
                  <div className="structure-text__list-item">
                    СПО - Среднее профессиональное образование
                  </div>
                  <div className="structure-text__list-item">
                    ИТР - Инженерно-технический работник
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="structure__list">
                  <div className="structure__list-item">
                    <div className="color green"></div>
                    <div className="text">Стартовая должность</div>
                  </div>
                  <div className="structure__list-item">
                    <div className="color orange"></div>
                    <div className="text">ИТР / Руководитель</div>
                  </div>
                  <div className="structure__list-item">
                    <div className="color blue"></div>
                    <div className="text">Рабочий / Оперативный персонал</div>
                  </div>
                  <div className="structure__list-item">
                    <div className="color light-green"></div>
                    <div className="text">Должность в другом подразделении</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="structure-wrapper">
          <ReactFlow
            nodes={data.Nodes}
            edges={data.Edges}
            zoomOnScroll={false}
            preventScrolling={false}
            onNodeClick={(event, node) => {
              console.log(node.data.id);
              handleId(node.id);
            }}
          >
            <Controls showFitView={false} showInteractive={false} />
          </ReactFlow>
        </div>
      </>
    </MainLayout>
  );
};

export default Structure;
