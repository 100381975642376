import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import PositionFooter from "./components/PositionFooter";

import "./styles.scss";

const Position16 = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">Начальник химического цеха</div>
        </div>
        <div className="node__list" style={{ height: 900, width: 2000 }}>
          <Card id={6} addClass="branch" b={700} l={200} width={340} />
          <Card id={63} addClass="branch" b={700} l={650} width={340}></Card>
          <Card id={134} addClass="branch" b={700} l={1100} width={340}></Card>
          <Card id={245} addClass="branch" b={700} l={1500} width={340}></Card>

          <Card id={65} addClass="power-station" b={400} l={850} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 300,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 50,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: "50%",
                height: 250,
                width: 200,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 40,
                left: "auto",
                right: 347,
                width: 410,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -12,
                  left: "auto",
                  right: -5,
                  transform: "none",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 40,
                right: "auto",
                width: 690,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -12,
                  left: "auto",
                  right: 338,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: -110,
                left: -300,
                width: 360,
                height: 110,
                borderTop: "4px solid #AAB2BA",
                borderRight: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -12,
                  left: "auto",
                  right: 343,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: -110,
                right: -355,
                width: 400,
                height: 110,
                borderTop: "4px solid #AAB2BA",
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -12,
                  left: 380,
                  right: 343,
                  transform: "none",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                bottom: "100%",
                right: "50%",
                height: 190,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -9,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-brecket"
                style={{ width: 1670, left: -5 }}
              ></div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 70,
                right: 347,
                width: 280,
                height: 110,
                borderTop: "4px solid #AAB2BA",
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -12,
                  left: 260,
                  right: 343,
                  transform: "none",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 70,
                left: -320,
                width: 40,
                height: 110,
                borderBottom: "4px solid #AAB2BA",
              }}
            ></div>
          </Card>

          <Card
            id={5}
            addClass="heating-network"
            b={550}
            l={200}
            width={350}
          ></Card>
          <Card
            id={162}
            addClass="power-station"
            b={400}
            l={200}
            width={350}
          ></Card>
          <Card
            id={105}
            addClass="power-station"
            b={250}
            l={200}
            width={350}
          ></Card>

          <Card
            id={220}
            addClass="power-station"
            b={550}
            l={1500}
            width={350}
          ></Card>
          <Card
            id={26}
            addClass="power-station"
            b={400}
            l={1500}
            width={350}
          ></Card>

          <Card id={67} addClass="power-station" b={250} l={600} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 300,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={246} addClass="power-station" b={250} l={1100} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 50,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={61}
            addClass="power-station"
            b={100}
            l={850}
            width={350}
          ></Card>
        </div>
        <PositionFooter />
      </div>
    </MainLayout>
  );
};

// [206, 207, 178, 174, 180, 177, 205, 109, 80, 107, 79] - Заместитель главного инженера филиала - начальник управления технической инспекции (key=06)

export default Position16;
