import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import SchemFooter from "./components/SchemFooter";

import "./styles.scss";

const APCSService = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Служба автоматизированных систем управления технологическими
            процессами
          </div>
        </div>
        <div className="node__list" style={{ height: 1160, width: 1900 }}>
          <Card id={29} addClass="end icon-other" b={1000} l={650}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 150,
                height: 220,
                borderLeft: "4px solid #5B646E",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={28} addClass="end icon-other" b={1000} l={150}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 400,
                height: 220,
                borderLeft: "4px solid #5B646E",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={26} addClass="end icon-other" b={850} l={1350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 225,
                height: 90,
                width: 505,
                borderRight: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={27} addClass="end icon-other" b={850} l={850}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 225,
                height: 90,
                width: 200,
                borderRight: "4px solid #5B646E",
                borderBottom: "4px solid #5B646E",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#5B646E"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={22} addClass="icon-supervisor" b={700} l={445}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 355,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  position: "absolute",
                  left: 10,
                  top: 12,
                }}
              >
                <div>Высшее образование, общий стаж 1-3 года,</div>
                <div>управленческий стаж от 1 года</div>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 107,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 12,
                  textAlign: "right",
                }}
              >
                <div>Высшее образование, общий стаж 1-3 года,</div>
                <div>управленческий стаж от 1 года</div>
              </div>
            </div>
          </Card>
          <Card id={25} addClass="icon-supervisor" b={550} l={750}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 50,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={24} addClass="icon-supervisor" b={400} l={750}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 150,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={21} addClass="icon-supervisor" b={550} l={150}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 400,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 22,
                  textAlign: "right",
                }}
              >
                <div>Высшее образование</div>
              </div>
            </div>
          </Card>
          <Card id={20} addClass="icon-supervisor" b={400} l={150}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 400,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 12,
                  textAlign: "right",
                }}
              >
                <div>3 года - среднее профессиональное образование,</div>
                <div>1 год - высшее образование</div>
              </div>
            </div>
          </Card>
          <Card id={23} addClass="icon-supervisor" b={250} l={1000}>
            <div
              className="node__arrow"
              style={{
                right: "100%",
                top: 20,
                width: 160,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  transform: "translateY(-50%)",
                  top: -2,
                  right: -2,
                  left: "auto",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", left: 30, top: 12 }}
              >
                <div>Высшее</div>
                <div>образование</div>
              </div>
            </div>
          </Card>
          <Card id={19} addClass="icon-supervisor" b={250} l={400}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 225,
                height: 45,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                className="node__arrow-text"
                style={{ position: "absolute", right: 10, top: 12 }}
              >
                <div>3 года - среднее профессиональное образование</div>
              </div>
            </div>
          </Card>
          <Card id={17} addClass="start icon-start" b={100} l={150}>
            <div
              className="node__arrow"
              style={{
                bottom: "100%",
                left: 350,
                width: 250,
                height: 24,
                borderLeft: "4px solid #AAB2BA",
                borderTop: "4px solid #AAB2BA",
                borderRight: "4px solid #AAB2BA",
              }}
            ></div>
          </Card>
          <Card id={18} addClass="start icon-start" b={100} l={650} />
        </div>
        <SchemFooter />
      </div>
    </MainLayout>
  );
};

export default APCSService;
