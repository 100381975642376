import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__grid">
          <div className="footer__grid-title">
            По вопросам профессионального развития обращаться
          </div>
          <div className="footer__grid-col">
            <div className="footer__logo">
              <img
                src="./images/footer_logo.png"
                alt=""
                className="footer__logo-img"
              />
            </div>
            <div className="footer__text">
              143421, Московская область, г.о. Красногорск, тер. автодороги
              «Балтия», км 26-й, д.5, стр. 3, офис 506
            </div>
            <a
              href="https://www.tplusgroup.ru/"
              className="footer__text-bold link"
            >
              tplusgroup.ru
            </a>
          </div>
          <div className="footer__grid-col col-title">
            <div className="footer__block">
              <div className="footer__block-title">Владимирский филиал</div>
              <div className="footer__block-subtitle">(674) 25-90</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Кировский филиал</div>
              <div className="footer__block-subtitle">(631) 23-68</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Коми филиал</div>
              <div className="footer__block-subtitle">(647) 42-64</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">
                Марий Эл и Чувашии филиал
              </div>
              <div className="footer__block-subtitle">(632) 42-41</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Мордовский филиал</div>
              <div className="footer__block-subtitle">(672) 22-65</div>
            </div>
          </div>
          <div className="footer__grid-col col-title">
            <div className="footer__block">
              <div className="footer__block-title">Нижегородский филиал</div>
              <div className="footer__block-subtitle">(670) 44-88</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Оренбургский филиал</div>
              <div className="footer__block-subtitle">(66) 2-77-01</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Пензенский филиал</div>
              <div className="footer__block-subtitle">(671) 23-27</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Пермский филиал</div>
              <div className="footer__block-subtitle">(640) 23-92</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Самарский филиал</div>
              <div className="footer__block-subtitle">(660) 62-38</div>
            </div>
          </div>
          <div className="footer__grid-col col-title">
            <div className="footer__block">
              <div className="footer__block-title">Саратовский филиал</div>
              <div className="footer__block-subtitle">(665) 65-44</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Свердловский филиал</div>
              <div className="footer__block-subtitle">(644) 10-30</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Удмуртский филиал</div>
              <div className="footer__block-subtitle">(633) 39-31</div>
            </div>
            <div className="footer__block">
              <div className="footer__block-title">Ульяновский филиал</div>
              <div className="footer__block-subtitle">(663) 26-11</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
