import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../ButtonBack/ButtonBack";
import MainLayout from "../MainLayout/MainLayout";

import "./styles.scss";

const Developing = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div className="container">
        <div className="back-wrpper">
          <ButtonBack
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div className="developing">
          <div className="developing__img">
            <img src="/developing.png" alt="" />
          </div>
          <div className="developing__title">
            Извините, данный раздел находится в разработке.
          </div>
          <button
            className="developing__btn"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span>Назад</span>
          </button>
        </div>
      </div>
    </MainLayout>
  );
};

export default Developing;
