import React, { useEffect, useState } from "react";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";

import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  data?: {
    name?: string;
    preview?: string;
    category?: string;
    education?: string;
    startPosition?: string;
    endPosition?: string;
    knowledge?: string;
    subdivision?: string;
    experience?: string;
  };
};

type Data = {
  name?: string;
  preview?: string;
  category?: string;
  education?: string;
  startPosition?: string;
  endPosition?: string;
  knowledge?: string;
  subdivision?: string;
  experience?: string;
};

type RequestData = {
  name?: string;
  preview?: string;
  category?: string;
  education?: string;
  startPosition?: string;
  endPosition?: string;
  knowledge?: string;
  subdivision?: string;
  experience?: string;
} | null;

const PositionNew = (props: any) => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const [data, setData] = useState<Data | null>();

  useEffect(() => {
    setData(state);
  }, []);

  return (
    <MainLayout>
      {data ? (
        <>
          <div className="container">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
            <div className="position">
              <div className="position__img">
                <img src={data.preview} alt="" />
              </div>
              <div className="position__info">
                <div className="position__info-title">{data.name}</div>
                {data.subdivision ? (
                  <div className="position__info-block">
                    <div className="position__info-subtitle">
                      Структурное подразделение
                    </div>
                    <div className="position__info-text">
                      {data.subdivision}
                    </div>
                  </div>
                ) : null}
                {data.category ? (
                  <div className="position__info-block">
                    <div className="position__info-subtitle">
                      Категория персонала
                    </div>
                    <div className="position__info-text">{data.category}</div>
                  </div>
                ) : null}
                {data.education ? (
                  <div className="position__info-block">
                    <div className="position__info-subtitle">
                      Требования к образованию
                    </div>
                    <div className="position__info-text">{data.education}</div>
                  </div>
                ) : null}

                {data.experience ? (
                  <div className="position__info-block">
                    <div className="position__info-subtitle">
                      Необходимый стаж работы
                    </div>
                    <div className="position__info-text">{data.experience}</div>
                  </div>
                ) : null}
                {data.startPosition ? (
                  <div className="position__info-block">
                    <div className="position__info-subtitle">
                      С какой должности назначается
                    </div>
                    <div className="position__info-text">
                      {data.startPosition}
                    </div>
                  </div>
                ) : null}
                {data.endPosition ? (
                  <div className="position__info-block">
                    <div className="position__info-subtitle">
                      На какую должность назначается
                    </div>
                    <div className="position__info-text">
                      {data.endPosition}
                    </div>
                  </div>
                ) : null}
                {data.knowledge ? (
                  <div className="position__info-block">
                    <div className="position__info-subtitle">
                      Дополнительные знания
                    </div>
                    <div className="position__info-text">{data.knowledge}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container">
          <ButtonBack
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="position__info-title center">
            Должность не найдена
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default PositionNew;
