import React from "react";
import { useNodes, BezierEdge } from "reactflow";
import {
  getSmartEdge,
  svgDrawSmoothLinePath,
} from "@tisoap/react-flow-smart-edge";

export default function CustomEdge(props: any) {
  const {
    id,
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    markerStart,
    markerEnd,
    label,
  } = props;

  const nodes = useNodes();

  const getSmartEdgeResponse = getSmartEdge({
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes,
    options: {
      nodePadding: 30,
      gridRatio: 30,
      drawEdge: svgDrawSmoothLinePath,
    },
  });

  // If the value returned is null, it means "getSmartEdge" was unable to find
  // a valid path, and you should do something else instead
  if (getSmartEdgeResponse === null) {
    return <BezierEdge {...props} />;
  }

  const { edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse;

  return (
    <>
      <path
        style={style}
        className="react-flow__edge-path"
        d={svgPathString}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
      {label && (
        <foreignObject
          width={140}
          height={200}
          x={edgeCenterX - 140 / 2}
          y={edgeCenterY - 200 / 2}
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <span className="edge-label">Стаж: {label}</span>
        </foreignObject>
      )}
    </>
  );
}
