import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import PositionFooter from "./components/PositionFooter";

import "./styles.scss";

const Position06 = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Заместитель главного инженера филиала - начальник управления
            технической инспекции
          </div>
        </div>
        <div className="node__list" style={{ height: 900, width: 1900 }}>
          <Card
            id={206}
            addClass="corporate-center"
            b={800}
            l={1000}
            width={350}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 50,
                height: 330,
                width: 100,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={207}
            addClass="corporate-center"
            b={800}
            l={1400}
            width={350}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 250,
                height: 360,
                width: 300,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={178} addClass="branch" b={700} l={300} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 100,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 300,
                height: 230,
                width: 250,
                borderLeft: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={174} addClass="branch" b={550} l={100} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 300,
                height: 100,
                width: 600,
                borderLeft: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={180} addClass="branch" b={550} l={850} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 50,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={177} addClass="branch" b={400} l={850} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 300,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 50,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                right: 200,
                height: 250,
                width: 200,
                borderRight: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  left: "auto",
                  right: -22,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 200,
                height: 250,
                width: 200,
                borderLeft: "4px solid #AAB2BA",
                borderBottom: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end" style={{}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 70,
                left: "auto",
                right: 347,
                width: 410,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -12,
                  left: "auto",
                  right: -5,
                  transform: "none",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={205} addClass="branch" b={350} l={100} width={350}></Card>

          <Card id={109} addClass="power-station" b={250} l={600} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 300,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={80} addClass="power-station" b={250} l={1100} width={350}>
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: 50,
                height: 100,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div className="node__arrow-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card
            id={107}
            addClass="power-station"
            b={100}
            l={600}
            width={350}
          ></Card>
          <Card
            id={79}
            addClass="power-station"
            b={100}
            l={1100}
            width={350}
          ></Card>
        </div>
        <PositionFooter />
      </div>
    </MainLayout>
  );
};

// [206, 207, 178, 174, 180, 177, 205, 109, 80, 107, 79] - Заместитель главного инженера филиала - начальник управления технической инспекции (key=06)

export default Position06;
