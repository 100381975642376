import React from "react";
import MainLayout from "../MainLayout/MainLayout";

const Panorama = () => {
  return (
    <MainLayout>
      <div className="container">
        <iframe src="/panorama/" title="iframe" />
      </div>
    </MainLayout>
  );
};

export default Panorama;
