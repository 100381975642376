import React, { useEffect, useMemo } from "react";
import ReactFlow, {
  Controls,
  Edge,
  Node,
  useNodesState,
  useEdgesState,
  MarkerType,
  Position,
} from "reactflow";

import MainLayout from "../MainLayout/MainLayout";

import "reactflow/dist/style.css";

import dagre from "dagre";
import FoureOutputInputNode from "../FoureOutputInputNode/FoureOutputInputNode";
import CustomEdge from "../CustomEdge/CustomEdge";

import axios from "axios";
import { url } from "../Position/Position";

import { useNavigate, useSearchParams } from "react-router-dom";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 500;
const nodeHeight = 300;

const position = { x: 0, y: 0 };
const edgeType = "special";

type InitialData = {
  Nodes: Node[];
  Edges: Edge[];
};

const initialData: InitialData = {
  Nodes: [],
  Edges: [],
};

const Tree = () => {
  const [searchParams, _] = useSearchParams();
  const startId = searchParams.get('start');
  const endId = searchParams.get('end');
  const navigate = useNavigate();
  const [nodes, setNodes] = useNodesState(initialData.Nodes);
  const [edges, setEdges] = useEdgesState(initialData.Edges);
  const nodeTypes = { customNode: FoureOutputInputNode };
  const edgeTypes = useMemo(() => ({ special: CustomEdge }), []);

  const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = 'BT') => {
    dagreGraph.setGraph({ rankdir: direction });
  
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
  
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
  
    dagre.layout(dagreGraph);
  
    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = Position.Bottom;
      node.sourcePosition = Position.Top;
  
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
  
      return node;
    });
  
    return { nodes, edges };
  };

  const startDrow = (data: InitialData) => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      data.Nodes,
      data.Edges,
      'BT'
    );

    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);
  };

  useEffect(() => {
    if (!startId || !endId) {
      return;
    }

    axios
      .get(url + "/construction/create-path", {
        params: {
          idStartPosition: startId,
          idEndPosition: endId,
        },
      })
      .then((response) => {
        const nodes = response.data.model.info;
        const edges = response.data.model.tree;

        const nodesArr: Node[] = [];

        nodes.forEach((node: { id: string; name: string }) => {
          const className = node.id.toString() === startId ? "start" : node.id.toString() === endId ? "end" : "";

          nodesArr.push({
            id: node.id.toString(),
            // className: `card card-test ${className}`,
            data: {
              label: node.name,
              class: className
            },
            position,
            type: "customNode",
          });
        });

        const edgesArr: Edge[] = [];

        edges.forEach((edge: { source: number; target: number }) => {
          let label = '';
          const node = nodes.filter((item: Node) => item.id.toString() === edge.source.toString());

          if (node[0] && node[0].experience) {
            label = node[0].experience;
          }

          edgesArr.push({
            id: edge.source + "-" + edge.target,
            source: edge.source.toString(),
            target: edge.target.toString(),
            type: edgeType,
            ...(label ? {label} : {})
          });
        });

        startDrow({ Nodes: nodesArr, Edges: edgesArr });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [startId, endId]);

  return (
    <MainLayout background={false}>
      <div className="structure-wrapper">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          edgeTypes={edgeTypes}
          zoomOnScroll={false}
          preventScrolling={false}
          nodeTypes={nodeTypes}
          minZoom={0.1}
          defaultEdgeOptions={{
            markerEnd: {
              type: MarkerType.Arrow,
              // strokeWidth: 1,
              // color: "#6B7881",
            },
            // style: {
            //   strokeWidth: 1,
            //   stroke: "#6B7881",
            // },
          }}
          onNodeClick={(event, node) => {
            navigate("/position", { state: { id: node.id } });
          }}
          fitView
        >
          <Controls showFitView={false} showInteractive={false} />
        </ReactFlow>
      </div>
    </MainLayout>
  );
};

export default Tree;
