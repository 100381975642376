import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBack from "../components/ButtonBack/ButtonBack";
import MainLayout from "../components/MainLayout/MainLayout";
import Card from "./components/Card";
import PositionFooter from "./components/PositionFooter";

import "./styles.scss";

const Position17 = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <div>
        <div className="container">
          <div className="back-wrpper">
            <ButtonBack
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="node__list-title">
            Начальник цеха автоматизированных систем управления технологическими
            процессами
          </div>
        </div>
        <div className="node__list" style={{ height: 900, width: 1950 }}>
          <Card
            id={243}
            addClass="branch"
            b={700}
            l={200}
            width={300}
            height={120}
          ></Card>
          <Card
            id={27}
            addClass="branch"
            b={700}
            l={510}
            width={300}
            height={120}
          ></Card>
          <Card
            id={88}
            addClass="branch"
            b={700}
            l={820}
            width={300}
            height={120}
          ></Card>
          <Card
            id={134}
            addClass="branch"
            b={700}
            l={1130}
            width={300}
            height={120}
          ></Card>
          <Card
            id={215}
            addClass="branch"
            b={700}
            l={1440}
            width={300}
            height={120}
          ></Card>

          <Card
            id={220}
            addClass="power-station"
            b={380}
            l={240}
            width={400}
            height={110}
          >
            <div
              className="node__arrow"
              style={{
                bottom: -85,
                right: -145,
                height: 120,
                width: 140,
                borderRight: "4px solid #AAB2BA",
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -11,
                  left: -8,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card
            id={188}
            addClass="power-station"
            b={530}
            l={240}
            width={400}
            height={110}
          >
            <div
              className="node__arrow"
              style={{
                top: "100%",
                left: "80%",
                height: 45,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="node__arrow"
              style={{
                bottom: -85,
                right: -145,
                height: 120,
                width: 140,
                borderRight: "4px solid #AAB2BA",
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -11,
                  left: -8,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={219} addClass="big power-station" b={300} l={700}>
            <div
              className="node__arrow"
              style={{
                bottom: "100%",
                left: 220,
                height: 230,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket"
                style={{ width: 1600, left: 40 }}
              ></div>
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              className="node__arrow"
              style={{
                top: 150,
                left: 220,
                height: 35,
                borderLeft: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-brecket down"
                style={{ width: 1480 }}
              ></div>
              <div
                className="node__arrow-end"
                style={{
                  top: -5,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>

          <Card id={247} addClass="big power-station" b={100} l={200}>
            <div
              className="node__arrow"
              style={{
                top: "50%",
                right: -49,
                height: 120,
                width: 140,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -11,
                  left: 130,
                  transform: "none",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
          <Card id={87} addClass="big power-station" b={100} l={700}></Card>
          <Card id={248} addClass="big power-station" b={100} l={1200}>
            <div
              className="node__arrow"
              style={{
                top: "50%",
                left: -49,
                height: 120,
                width: 140,
                borderTop: "4px solid #AAB2BA",
              }}
            >
              <div
                className="node__arrow-end"
                style={{
                  top: -11,
                  left: -8,
                  transform: "rotate(180deg)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="46"
                  viewBox="0 0 28 46"
                  fill="none"
                >
                  <path
                    d="M6.24219 40.2595L21.0923 25.4095C22.4923 24.0095 22.4923 21.7195 21.0923 20.3295L6.24219 5.47949"
                    stroke="#AAB2BA"
                    stroke-width="10.58"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Card>
        </div>
        <PositionFooter />
      </div>
    </MainLayout>
  );
};

// [179, 183, 184, 178, 174, 176, 175, 177, 188, 29] - Главный инженер филиала (key: 03)

export default Position17;
