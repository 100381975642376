import { Link } from "react-router-dom";
import { Edge, MarkerType, Position, Node } from "reactflow";

export type Nodes = {
  id: string | undefined;
  Nodes: Node[] | undefined;
  Edges: Edge[] | undefined;
  title: string | undefined;
};

export const chemicalService: Nodes = {
  title: "Химическая служба тепловых сетей",
  id: "1",
  Nodes: [
    {
      id: "1-add",
      position: { x: 350, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Лаборант химического анализа 2,3,4,5 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Bottom,
    },
    {
      id: "1",
      position: { x: 350, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Лаборант химического анализа 2,3,4,5 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Top,
    },
    {
      id: "2",
      position: { x: 650, y: 350 },
      data: {
        label: (
          <Link to="/position">
            <span>Техник 1 категории</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "3",
      position: { x: 650, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер 1 категории</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "4",
      position: { x: 950, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник химической службы ТС</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "5",
      position: { x: 1150, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник центральной химической лаборатории</span>
          </Link>
        ),
      },
      className: "card card-another",
      targetPosition: Position.Left,
    },
    {
      id: "6",
      position: { x: 1150, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель/ Начальник химического цеха</span>
          </Link>
        ),
      },
      className: "card card-another",
      targetPosition: Position.Left,
    },
    {
      id: "7",
      position: { x: 1150, y: 0 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник группы режимов и наладки ТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      targetPosition: Position.Left,
    },
  ],
  Edges: [
    {
      id: "e1-2",
      source: "1",
      target: "2",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "Стаж не менее 2 лет",
    },
    {
      id: "e1-3",
      source: "1-add",
      target: "3",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "Стаж не менее 3 лет",
    },
    {
      id: "e2-4",
      source: "2",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВО, 3 года",
    },
    {
      id: "e3-4",
      source: "3",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВО, 3 года",
    },
    {
      id: "e4-5",
      source: "4",
      target: "5",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e4-6",
      source: "4",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e4-7",
      source: "4",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
  ],
};

export const electricalService: Nodes = {
  title: "Служба электрохозяйства",
  id: "2",
  Nodes: [
    {
      id: "1-b",
      position: { x: 350, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электромонтер по ремонту и обслуживанию электрооборудования 4,5,6
              разряда
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Bottom,
    },
    {
      id: "1",
      position: { x: 350, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электромонтер по ремонту и обслуживанию электрооборудования 4,5,6
              разряда
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
    },
    {
      id: "2",
      position: { x: 750, y: 232 },
      data: {
        label: (
          <Link to="/position">
            <span>Мастер ПУ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "3",
      position: { x: 350, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер 1 категории ЭТЛ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "4",
      position: { x: 750, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер ЭТЛ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "5-t",
      position: { x: 1100, y: 392 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник электротехнической лаборатории</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "5",
      position: { x: 1100, y: 392 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник электротехнической лаборатории</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "6-b",
      position: { x: 1100, y: 232 },
      data: {
        label: (
          <Link to="/position">
            <span>Старший мастер ПУ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "6",
      position: { x: 1100, y: 232 },
      data: {
        label: (
          <Link to="/position">
            <span>Старший мастер ПУ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "7",
      position: { x: 1550, y: 224 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник службы электрохозяйства</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "8",
      position: { x: 1550, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель главного инженера по эксплуатации ТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Top,
      targetPosition: Position.Bottom,
    },
  ],
  Edges: [
    {
      id: "e1-2",
      source: "1",
      target: "2",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="60" dy="0">
            1 год ВО,
          </tspan>
          <tspan x="60" dy="20">
            3 года ССП,
          </tspan>
          <tspan x="60" dy="20">
            5 лет без образования
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e1-3",
      source: "1-b",
      target: "3",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВО",
    },
    {
      id: "e3-4",
      source: "3",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e4-5",
      source: "4",
      target: "5",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВО, 3 года",
    },
    {
      id: "e2-6",
      source: "2",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "1 год ВО, 3 года ССП",
    },
    {
      id: "e6-5",
      source: "6-b",
      target: "5-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВО, 3 года",
    },
    {
      id: "e6-7",
      source: "6",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="90" dy="0">
            5 лет стаж в отрасли,
          </tspan>
          <tspan x="90" dy="20">
            3 года управленческий стаж, ВО,
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e7-8",
      source: "7",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
  ],
};

export const acsService: Nodes = {
  title: "Служба АСУТП",
  id: "3",
  Nodes: [
    {
      id: "1",
      position: { x: 150, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Слесарь по КИПиА 4,5,6,7 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
    },
    {
      id: "2",
      position: { x: 150, y: 320 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электрослесарь по ремонту и обслуживанию автоматики и средств
              измерений 4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
    },
    {
      id: "3-l",
      position: { x: 600, y: 350 },
      data: {
        label: (
          <Link to="/position">
            <span>Мастер участка КИПиА</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "3-b",
      position: { x: 600, y: 350 },
      data: {
        label: (
          <Link to="/position">
            <span>Мастер участка КИПиА</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Bottom,
    },
    {
      id: "3",
      position: { x: 600, y: 350 },
      data: {
        label: (
          <Link to="/position">
            <span>Мастер участка КИПиА</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
    },
    {
      id: "4",
      position: { x: 1000, y: 358 },
      data: {
        label: (
          <Link to="/position">
            <span>Старший мастер</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "5",
      position: { x: 1400, y: 350 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник участка КИПиА</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "6-b",
      position: { x: 1800, y: 450 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник службы АСУ ТП</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "6",
      position: { x: 1800, y: 450 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник службы АСУ ТП</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
    },
    {
      id: "7",
      position: { x: 600, y: 550 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер 1 категории</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
    },
    {
      id: "8",
      position: { x: 1000, y: 550 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "9",
      position: { x: 1400, y: 550 },
      data: {
        label: (
          <Link to="/position">
            <span>Главный специалист</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "10",
      position: { x: 2100, y: 250 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ПТО</span>
          </Link>
        ),
      },
      className: "card card-another",
      targetPosition: Position.Left,
    },
    {
      id: "11",
      position: { x: 2100, y: 150 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник управления АСУТП</span>
          </Link>
        ),
      },
      className: "card card-another",
      targetPosition: Position.Left,
    },
    {
      id: "12",
      position: { x: 2100, y: 50 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Заместитель главного инженера по эксплуатации котельных и ТС
            </span>
          </Link>
        ),
      },
      className: "card card-another",
      targetPosition: Position.Left,
    },
    {
      id: "13",
      position: { x: 2100, y: -50 },
      data: {
        label: (
          <Link to="/position">
            <span>Технический директор-главный инженер ТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      targetPosition: Position.Left,
    },
  ],
  Edges: [
    {
      id: "e1-3",
      source: "1",
      target: "3",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "СПО, 3 года",
    },
    {
      id: "e2-3",
      source: "2",
      target: "3-l",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "СПО, 3 года",
    },
    {
      id: "e3-4",
      source: "3",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "3 года, СПО, 1 год, ВО",
    },
    {
      id: "e4-5",
      source: "4",
      target: "5",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "СПО, 3 года",
    },
    {
      id: "e3-7",
      source: "3-b",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВО",
    },
    {
      id: "e7-8",
      source: "7",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e8-9",
      source: "8",
      target: "9",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e9-6",
      source: "9",
      target: "6-b",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="85" dy="0">
            ВО, общий стаж 1-3 года,
          </tspan>
          <tspan x="85" dy="20">
            управленческий стаж от 1 года
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e5-6",
      source: "5",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="85" dy="0">
            ВО, общий стаж 1-3 года,
          </tspan>
          <tspan x="85" dy="20">
            управленческий стаж от 1 года
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e6-10",
      source: "6",
      target: "10",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e6-11",
      source: "6",
      target: "11",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e6-12",
      source: "6",
      target: "12",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e6-13",
      source: "6",
      target: "13",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
  ],
};

export const heatingNetworks: Nodes = {
  title: "Район тепловых сетей",
  id: "4",
  Nodes: [
    {
      id: "1",
      position: { x: 200, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Слесарь по обслуживанию ТС 4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
    },
    {
      id: "2",
      position: { x: 200, y: 300 },
      data: {
        label: (
          <Link to="/position">
            <span>Машинист насосных установок 2,3,4,5 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
    },
    {
      id: "3",
      position: { x: 200, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Электрогазосварщик 5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
    },
    {
      id: "4",
      position: { x: 200, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Слесарь по обслуживанию тепловых пунктов 4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "5",
      position: { x: 200, y: 650 },
      data: {
        label: (
          <Link to="/position">
            <span>Оператор теплового пункта 2,3,4 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Left,
    },
    {
      id: "6-r",
      position: { x: 600, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Техник, Техник 1 категории, Техник 2 категории</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "6",
      position: { x: 600, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Техник, Техник 1 категории, Техник 2 категории</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "7",
      position: { x: 600, y: 525 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер участка ЦТП</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "8-t",
      position: { x: 600, y: 365 },
      data: {
        label: (
          <Link to="/position">
            <span>Мастер производственного участка</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "8",
      position: { x: 600, y: 365 },
      data: {
        label: (
          <Link to="/position">
            <span>Мастер производственного участка</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "9",
      position: { x: 900, y: 214.5 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "10",
      position: { x: 900, y: 50 },
      data: {
        label: (
          <Link to="/position">
            <span>Группа ТПиР ТС </span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Top,
      targetPosition: Position.Bottom,
    },
    {
      id: "11",
      position: { x: 900, y: 365 },
      data: {
        label: (
          <Link to="/position">
            <span>Старший мастер производственного участка</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "12",
      position: { x: 1200, y: 373 },
      data: {
        label: (
          <Link to="/position">
            <span>Главный специалист РТС</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "13",
      position: { x: 1600, y: 373 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник района тепловых сетей </span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "14",
      position: { x: 1800, y: 280 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ПТО ТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "15",
      position: { x: 1800, y: 220 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ОДС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "16",
      position: { x: 1800, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Заместитель главного инженера ТС по обеспечению технического
              состояния
            </span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "17",
      position: { x: 1800, y: 20 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель главного инженера ТС по эксплуатации</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
  ],
  Edges: [
    {
      id: "e5-4",
      source: "5",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e1-6",
      source: "1",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "СПО",
    },
    {
      id: "e4-7",
      source: "4",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВО 1 год, СПО 3 года",
    },
    {
      id: "e7-8",
      source: "7",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e6-8",
      source: "6",
      target: "8-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: "СПО, 1 год",
    },
    {
      id: "e6-9",
      source: "6-r",
      target: "9",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e9-10",
      source: "9",
      target: "10",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e8-11",
      source: "8",
      target: "11",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "СПО, 3 года",
    },
    {
      id: "e11-12",
      source: "11",
      target: "12",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e12-13",
      source: "12",
      target: "13",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="80" dy="0">
            ВО, 5 лет,
          </tspan>
          <tspan x="80" dy="20">
            управленческий стаж 3 года
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e13-14",
      source: "13",
      target: "14",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e13-15",
      source: "13",
      target: "15",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e13-16",
      source: "13",
      target: "16",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e13-17",
      source: "13",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
  ],
};

export const boilerRoom: Nodes = {
  title: "Район котельных",
  id: "5",
  Nodes: [
    {
      id: "1",
      position: { x: 200, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Оператор котельной 2,3,4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "2",
      position: { x: 200, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Слесарь по обслуживанию тепловых сетей 4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "3",
      position: { x: 200, y: 330 },
      data: {
        label: (
          <Link to="/position">
            <span>Аппаратчик ХВО 2,3,4,5 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Left,
    },
    {
      id: "4",
      position: { x: 500, y: 208 },
      data: {
        label: (
          <Link to="/position">
            <span>Мастер производственного участка</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "5",
      position: { x: 900, y: 208 },
      data: {
        label: (
          <Link to="/position">
            <span>Старший мастер производственного участка</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "6",
      position: { x: 1200, y: 216 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник участка котельныха</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "7",
      position: { x: 1500, y: 208 },
      data: {
        label: (
          <Link to="/position">
            <span>Главный специалист/ Заместитель начальника РК</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "8",
      position: { x: 1900, y: 216 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник района котельных</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "9",
      position: { x: 1900, y: 50 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель главного инженера ТС по котельным </span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
  ],
  Edges: [
    {
      id: "e3-1",
      source: "3",
      target: "1",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e3-2",
      source: "3",
      target: "2",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e1-4",
      source: "1",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e2-4",
      source: "2",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "СПО, 1 год",
    },
    {
      id: "e4-5",
      source: "4",
      target: "5",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="60" dy="0">
            СПО, 1 год с опытом
          </tspan>
          <tspan x="60" dy="20">
            эксплуатации котлов
          </tspan>
          <tspan x="60" dy="20">
            не менее 1 года
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e5-6",
      source: "5",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e6-7",
      source: "6",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e7-8",
      source: "7",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="75" dy="0">
            ВО, 5 лет,
          </tspan>
          <tspan x="75" dy="20">
            управленческий стаж 5 лет
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e8-9",
      source: "8",
      target: "9",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
  ],
};

export const trajectories: Nodes[] = [
  {
    id: "1",
    title: "Начальник оперативно-диспетчерской службы тепловых сетей",
    Nodes: [
      {
        id: "1",
        position: { x: 200, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены тепловых сетей</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 200, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Диспетчер тепловых сетей</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель начальника диспетчерской службы</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 200, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Старший начальник смены тепловых сетей</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 200, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>Инженер по режимам группы ПТО</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 600, y: 320 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Начальник оперативно-диспетчерской службы тепловых сетей
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 900, y: 450 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера тепловых сетей по эксплуатации
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8-b",
        position: { x: 1200, y: 320 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор-главный инженер тепловых сетей</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Bottom,
      },
      {
        id: "8",
        position: { x: 1200, y: 327.3 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор-главный инженер тепловых сетей</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 1200, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор-главный инженер тепловых сетей</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-6",
        source: "1",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-6",
        source: "2",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-6",
        source: "3",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-6",
        source: "4",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-6",
        source: "5",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-7",
        source: "6",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-8",
        source: "7",
        target: "8-b",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-8",
        source: "6",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-9",
        source: "6",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "2",
    title:
      "Заместитель технического директора (главного инженера) ТС по обеспечению технического состояния",
    Nodes: [
      {
        id: "1",
        position: { x: 200, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник эксплуатационного участка/ района ТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 200, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник оперативно-диспетчерской службы</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 200, y: 320 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник ПТО</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 200, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Руководитель группы службы диагностики/ Начальник отдела
                диагностики
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 200, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Начальник отдела в структуре заместителя технического
                директора-Главного инженера по ОТС
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 200, y: 650 },
        data: {
          label: (
            <Link to="/position">
              <span>Руководитель группы отдела ОТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 600, y: 320 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Начальник оперативно-диспетчерской службы тепловых сетей
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 1000, y: 334.5 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор - Главный инженер ТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 1000, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель Главного инженера филиала по обеспечению
                технического состояния
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-7",
        source: "1",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-7",
        source: "2",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-7",
        source: "3",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-7",
        source: "4",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-7",
        source: "5",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-7",
        source: "6",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-8",
        source: "7",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-9",
        source: "7",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "3",
    title:
      "Заместитель технического директора (главного инженера) ТС по эксплуатации",
    Nodes: [
      {
        id: "1",
        position: { x: 200, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник эксплуатационного участка/ района ТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 200, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник службы эксплуатации котельных</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 200, y: 320 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник оперативно-диспетчерской службы</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 200, y: 450 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник ПТО</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 200, y: 550 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник группы режимов и наладки</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 200, y: 650 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник службы электрохозяйства</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 200, y: 800 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник службы АСУ ТП</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 500, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (тепловых сетей) по эксплуатации
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 800, y: 407 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор- Главный инженер ТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 800, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера филиала по тепловым сетям
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-8",
        source: "1",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-8",
        source: "2",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-8",
        source: "3",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-8",
        source: "4",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-8",
        source: "5",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-8",
        source: "6",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-8",
        source: "7",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e8-9",
        source: "8",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e8-10",
        source: "8",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "4",
    title:
      "Главный инженер района котельных (района тепловых сетей)/Заместитель главного инженера ТС по котельным/Начальник котельных",
    Nodes: [
      {
        id: "1-r",
        position: { x: 200, y: 107 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены тепловых сетей</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "1",
        position: { x: 200, y: 107 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены тепловых сетей</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Top,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 500, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник района котельных/участка районных котельных</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 500, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник оперативно-диспетчерской службы</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 500, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник участка ЦРС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 500, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены станции</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 500, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник КТЦ</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7-t",
        position: { x: 800, y: 250 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (тепловых сетей) по эксплуатации
                котельных
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Top,
      },
      {
        id: "7",
        position: { x: 800, y: 250 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (тепловых сетей) по эксплуатации
                котельных
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 1100, y: 220 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор-главный инженер (тепловых сетей)</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 1100, y: 350 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (станции) по эксплуатации
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 1100, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (станции) по эксплуатации
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-2",
        source: "1-r",
        target: "2",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-7",
        source: "1",
        target: "7-t",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-7",
        source: "2",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-7",
        source: "3",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-7",
        source: "4",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-7",
        source: "5",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-7",
        source: "6",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-8",
        source: "7",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-9",
        source: "7",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-10",
        source: "7",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "5",
    title: "Технический директор-главный инженер (тепловых сетей)",
    Nodes: [
      {
        id: "1-t",
        position: { x: 150, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник эксплуатационного участка/ района ТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Top,
        targetPosition: Position.Left,
      },
      {
        id: "1",
        position: { x: 150, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник эксплуатационного участка/ района ТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 200, y: 250 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник ПТО</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 500, y: 150 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель главного инженера ТС по эксплуатации</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 500, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера ТС по обеспечению технического
                состояния
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 500, y: 550 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера ТС по эксплуатации котельных
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6-b",
        position: { x: 850, y: 290 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор- Главный инженер ТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
      },
      {
        id: "6-t",
        position: { x: 850, y: 290 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор-Главный инженер ТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Top,
        targetPosition: Position.Top,
      },
      {
        id: "6",
        position: { x: 850, y: 290 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор-Главный инженер ТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7-b",
        position: { x: 1100, y: 150 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера филиала по тепловым сетям
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 1100, y: 150 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера филиала по тепловым сетям
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 1100, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Руководитель группы развития/ Заместитель директора филиала по
                коммерции и развитию
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9-t",
        position: { x: 1400, y: 297 },
        data: {
          label: (
            <Link to="/position">
              <span>Главный инженер филиала</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Top,
      },
      {
        id: "9",
        position: { x: 1400, y: 297 },
        data: {
          label: (
            <Link to="/position">
              <span>Главный инженер филиала</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 1700, y: 304 },
        data: {
          label: (
            <Link to="/position">
              <span>Директор филиала</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "11",
        position: { x: 1700, y: 600 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор филиала АО "ЭнергосбыТ Плюс"</span>
            </Link>
          ),
        },
        className: "card card-yellow",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "12",
        position: { x: 1700, y: 0 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор филиала АО "ЭнергосбыТ Плюс"</span>
            </Link>
          ),
        },
        className: "card card-supervisor",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-3",
        source: "1",
        target: "3",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-6",
        source: "1-t",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-4",
        source: "1",
        target: "4",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-3",
        source: "2",
        target: "3",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-4",
        source: "2",
        target: "4",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-6",
        source: "7-b",
        target: "6-t",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-8",
        source: "6-b",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-6",
        source: "3",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-7",
        source: "3",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-11",
        source: "6-b",
        target: "11",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-9",
        source: "6",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e9-10",
        source: "9",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-6",
        source: "5",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-6",
        source: "2",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-6",
        source: "4",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-10",
        source: "6-b",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-9",
        source: "7",
        target: "9-t",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-12",
        source: "6-t",
        target: "12",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "6",
    title: "Начальник смены электростанции",
    Nodes: [
      {
        id: "1",
        position: { x: 200, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены электрического цеха</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 200, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены котлотурбинного цеха</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4-t",
        position: { x: 500, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены электростанции</span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Top,
        targetPosition: Position.Left,
      },
      {
        id: "4-b",
        position: { x: 500, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены электростанции</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 500, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены электростанции</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 700, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Старший начальник смены электростанции</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 700, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник ПТО</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 1200, y: 0 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель главного инженера станции по эксплуатации</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 1200, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник Электрического цеха</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 1200, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник Котлотурбинного цеха</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 1200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник цеха АСУТП</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "11",
        position: { x: 1200, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник/заместитель начальника ПТО тепловых сетей</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "12",
        position: { x: 1500, y: -300 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Начальник теплотехнического отдела производственно-технического
                управления
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "13",
        position: { x: 1500, y: -150 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Начальник электротехнического отдела производственно-
                технического управления
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-4",
        source: "1",
        target: "4",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-4",
        source: "2",
        target: "4",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-4",
        source: "3",
        target: "4",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-5",
        source: "4-b",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-6",
        source: "4-b",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-7",
        source: "4",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-8",
        source: "4",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-9",
        source: "4",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-10",
        source: "4",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-11",
        source: "4",
        target: "11",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-12",
        source: "4-t",
        target: "12",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-13",
        source: "4-t",
        target: "13",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "7",
    title: "Начальник химического цеха",
    Nodes: [
      {
        id: "1",
        position: { x: 200, y: 307 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены химического (ХЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 500, y: 0 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены котлотурбинного цеха (КТЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 500, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель начальника химического цеха (ХЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 500, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Главный специалист или ведущий инженер химического цеха (ХЦ)
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 800, y: 0 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник химической лаборатории</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
      },
      {
        id: "6-t",
        position: { x: 800, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник химического цеха (ХЦ)</span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Top,
      },
      {
        id: "6",
        position: { x: 800, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник химического цеха (ХЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 800, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник ПТО</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Top,
      },
      {
        id: "8",
        position: { x: 1200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель главного инженера станции по эксплуатации</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 1200, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник химической службы</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 1200, y: -200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник отдела химводоподготовки</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "11",
        position: { x: 1200, y: -100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник центральной химической лаборатории</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "12",
        position: { x: 1200, y: 0 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Начальник отдела / главный специалист
                производственно-технического управления
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "13",
        position: { x: 1200, y: 150 },
        data: {
          label: (
            <Link to="/position">
              <span>Главный специалист отдела БТиОЗ</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-3",
        source: "1",
        target: "3",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-4",
        source: "1",
        target: "4",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-6",
        source: "1",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-6",
        source: "2",
        target: "6-t",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-6",
        source: "3",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-6",
        source: "4",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-6",
        source: "5",
        target: "6-t",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-7",
        source: "6-t",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-8",
        source: "6",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-9",
        source: "6",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-10",
        source: "6",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-11",
        source: "6",
        target: "11",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-12",
        source: "6",
        target: "12",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-13",
        source: "6",
        target: "13",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "8",
    title: "Начальник котлотурбинного цеха (КТЦ)",
    Nodes: [
      {
        id: "1",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены котлотурбинного (КТЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 500, y: 180 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель начальника котлотурбинного цеха (КТЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 500, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Главный специалист или ведущий инженер котлотурбинного цеха
                (КТЦ)
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 800, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены станции</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
      },
      {
        id: "5-t",
        position: { x: 800, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник котлотурбинного цеха (КТЦ)</span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Top,
      },
      {
        id: "5",
        position: { x: 800, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник котлотурбинного цеха (КТЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 800, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник ПТО</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Top,
      },
      {
        id: "7",
        position: { x: 1300, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель главного инженера станции по эксплуатации</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 1300, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера станции по обеспечению
                технического состояния – начальник отдела ОТС
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 1300, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (тепловых сетей) по эксплуатации
                котельных
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 1300, y: -400 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник управления БТиОЗ</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "11",
        position: { x: 1300, y: -300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник производственно-технического управления</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "12",
        position: { x: 1300, y: -200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник управления технической инспекции</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "13",
        position: { x: 1300, y: -100 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Начальник отдела производственно-технического управления
              </span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "14",
        position: { x: 1300, y: 0 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник отдела ОТС</span>
            </Link>
          ),
        },
        className: "card card-another",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-2",
        source: "1",
        target: "2",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-3",
        source: "1",
        target: "3",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-5",
        source: "1",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-5",
        source: "2",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-5",
        source: "3",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-5",
        source: "4",
        target: "5-t",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-6",
        source: "5-t",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-7",
        source: "5",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-8",
        source: "5",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-9",
        source: "5",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-10",
        source: "5",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-11",
        source: "5",
        target: "11",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-12",
        source: "5",
        target: "12",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-13",
        source: "5",
        target: "13",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-14",
        source: "5",
        target: "14",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "9",
    title: "Начальник электроцеха (ЭЦ)",
    Nodes: [
      {
        id: "1",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены электрического цеха (ЭЦ) </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 500, y: 180 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель начальника электрического цеха (ЭЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 500, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Главный специалист или ведущий инженер электрического цеха (ЭЦ)
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 500, y: 550 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник электро-технической лаборатории</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5-t",
        position: { x: 800, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник электрического цеха (ЭЦ)</span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Top,
        targetPosition: Position.Top,
      },
      {
        id: "5",
        position: { x: 800, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник электрического цеха (ЭЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 1100, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель главного инженера станции по эксплуатации</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 1100, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера станции по обеспечению
                технического состояния – Начальник отдела ОТС
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 1400, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор - главный инженер станции</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 1600, y: -400 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник управления БТиОЗ</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 1600, y: -300 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Главный специалист электротехнического оборудования филиала{" "}
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "11",
        position: { x: 1600, y: -200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник электротехнического отдела филиала </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "12",
        position: { x: 1600, y: -100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник управления технической инспекции</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "13",
        position: { x: 1600, y: 0 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Начальник отдела производственно-технического управления
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "14",
        position: { x: 1600, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник отдела ОТС</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-2",
        source: "1",
        target: "2",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-3",
        source: "1",
        target: "3",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-5",
        source: "1",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-5",
        source: "2",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-5",
        source: "3",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-5",
        source: "4",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-6",
        source: "5",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-7",
        source: "5",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-8",
        source: "5",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-8",
        source: "6",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e7-8",
        source: "7",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-9",
        source: "5-t",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-10",
        source: "5-t",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-11",
        source: "5-t",
        target: "11",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-12",
        source: "5-t",
        target: "12",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-13",
        source: "5-t",
        target: "13",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-14",
        source: "5-t",
        target: "14",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "10",
    title: "Начальник цеха АСУ ТП",
    Nodes: [
      {
        id: "1-b",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальника смены цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
      },
      {
        id: "1",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальника смены цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2-b",
        position: { x: 200, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель начальника цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Bottom,
      },
      {
        id: "2",
        position: { x: 200, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель начальника цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Top,
      },
      {
        id: "3-t",
        position: { x: 200, y: 520 },
        data: {
          label: (
            <Link to="/position">
              <span>Ведущий инженер/ Главный специалист цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Top,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 200, y: 520 },
        data: {
          label: (
            <Link to="/position">
              <span>Ведущий инженер/ Главный специалист цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4-t",
        position: { x: 500, y: 408 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Top,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 500, y: 408 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 800, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (станции) по эксплуатации
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6-t",
        position: { x: 1100, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор - главный инженер (станции)</span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Right,
        targetPosition: Position.Top,
      },
      {
        id: "6",
        position: { x: 1100, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор - главный инженер (станции)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 1300, y: -100 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Руководитель группы или ведущий инженер управления АСУ ТП
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 1300, y: 0 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник управления АСУ ТП филиала</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 1300, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник отдела АСУ ТП филиала</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 1300, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Начальник отдела производственно-технического управления
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "11",
        position: { x: 1300, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник отдела ОТС</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-4",
        source: "1",
        target: "4",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-4",
        source: "2",
        target: "4",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-4",
        source: "3",
        target: "4",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-2",
        source: "1-b",
        target: "2",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-2",
        source: "3-t",
        target: "2-b",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-5",
        source: "4",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-6",
        source: "5",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-6",
        source: "4",
        target: "6-t",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-7",
        source: "4-t",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-8",
        source: "4-t",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-9",
        source: "4-t",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-10",
        source: "4-t",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-11",
        source: "4-t",
        target: "11",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "11",
    title:
      "Заместитель технического директора/главного инженера станции по эксплуатации",
    Nodes: [
      {
        id: "1-b",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены станции</span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Bottom,
        targetPosition: Position.Left,
      },
      {
        id: "1",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены станции</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 200, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник котлотурбинного цеха (КТЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Top,
      },
      {
        id: "3",
        position: { x: 200, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник электрического цеха (ЭЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 200, y: 600 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник ПТО</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 200, y: 700 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 500, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (станции) по эксплуатации
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 800, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор -главный инженер (станции)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 850, y: 100 },
        data: {
          label: (
            <Link to="/position">
              <span>Заместитель главного инженера филиала по генерации</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 850, y: 200 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник управления коммерции и развития</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 850, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник управления развития активов</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-6",
        source: "1",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-6",
        source: "2",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-6",
        source: "3",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-6",
        source: "4",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-6",
        source: "5",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e1-2",
        source: "1-b",
        target: "2",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-7",
        source: "6",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-8",
        source: "6",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-9",
        source: "6",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e6-10",
        source: "6",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "12",
    title:
      "Заместитель технического директора/главного инженера станции по обеспечению технического состояния",
    Nodes: [
      {
        id: "1",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник котлотурбинного цеха (КТЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 200, y: 400 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник электрического цеха (ЭЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 200, y: 500 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник отдела ОТС</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 200, y: 600 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник участка ЦРС</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5-t",
        position: { x: 500, y: 420 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (станции) по обеспечению
                технического состояния – Начальник отдела ОТС
              </span>
            </Link>
          ),
        },
        className: "card",
        sourcePosition: Position.Top,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 500, y: 420 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (станции) по обеспечению
                технического состояния – Начальник отдела ОТС
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 800, y: 449 },
        data: {
          label: (
            <Link to="/position">
              <span>Технический директор - главный инженер (станции)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 1000, y: 0 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Ведущий/ главный специалист департамента генерации ПАО "Т Плюс"
              </span>
            </Link>
          ),
        },
        className: "card card-supervisor",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "8",
        position: { x: 1000, y: 120 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера филиала по обеспечению
                технического состояния - начальник управления по ОТС
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "9",
        position: { x: 1000, y: 255 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник управления технической инспекции</span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "10",
        position: { x: 1000, y: 350 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Руководитель группы развития/ Начальник управления развития
                активов
              </span>
            </Link>
          ),
        },
        className: "card card-start",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [
      {
        id: "e1-5",
        source: "1",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e2-5",
        source: "2",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e3-5",
        source: "3",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e4-5",
        source: "4",
        target: "5",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-6",
        source: "5",
        target: "6",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-7",
        source: "5-t",
        target: "7",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-8",
        source: "5-t",
        target: "8",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-9",
        source: "5-t",
        target: "9",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
      {
        id: "e5-10",
        source: "5-t",
        target: "10",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#39D2A3",
          strokeWidth: 2,
        },
        style: {
          strokeWidth: 3,
          stroke: "#39D2A3",
        },
        type: "smoothstep",
      },
    ],
  },
  {
    id: "13",
    title: "Технический директор/главный инженер (станции)",
    Nodes: [
      {
        id: "1",
        position: { x: 200, y: 300 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник смены станции</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "2",
        position: { x: 200, y: 450 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник котлотурбинного цеха (КТЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "3",
        position: { x: 200, y: 600 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник электрического цеха (ЭЦ)</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "4",
        position: { x: 200, y: 750 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник ПТО</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "5",
        position: { x: 200, y: 900 },
        data: {
          label: (
            <Link to="/position">
              <span>Начальник цеха АСУ ТП</span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "6",
        position: { x: 500, y: 520 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (станции) по эксплуатации
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
      {
        id: "7",
        position: { x: 500, y: 720 },
        data: {
          label: (
            <Link to="/position">
              <span>
                Заместитель главного инженера (станции) по обеспечению
                технического состояния – начальник отдела (ОТС)
              </span>
            </Link>
          ),
        },
        className: "card card-worker",
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      },
    ],
    Edges: [],
  },
  {
    id: "14",
    title: "Начальник управления АСУ ТП",
    Nodes: [],
    Edges: [],
  },
  {
    id: "15",
    title: "Начальник отдела эксплуатации АСУ ТП",
    Nodes: [],
    Edges: [],
  },
  {
    id: "16",
    title: "Заместитель главного инженера филиала по генерации (эксплуатации)",
    Nodes: [],
    Edges: [],
  },
  {
    id: "17",
    title:
      "Заместитель главного инженера филиала по эксплуатации тепловых сетей",
    Nodes: [],
    Edges: [],
  },
  {
    id: "18",
    title:
      "Заместитель главного инженера филиала по обеспечению технического состояния (начальник управления по обеспечению технического состояния)",
    Nodes: [],
    Edges: [],
  },
  {
    id: "19",
    title:
      "Заместитель главного инженера филиала-начальник управления технической инспекции",
    Nodes: [],
    Edges: [],
  },
  {
    id: "20",
    title: "Главный инженер филиала",
    Nodes: [],
    Edges: [],
  },
  {
    id: "21",
    title: "Директор филиала",
    Nodes: [],
    Edges: [],
  },
  {
    id: "22",
    title:
      'Руководителя технической (тепловой/электр.) инспекции филиала АО "ЭнергосбыТ Плюс"',
    Nodes: [],
    Edges: [],
  },
  {
    id: "23",
    title: 'Технический директор. Филиала АО "ЭнергосбыТ Плюс"',
    Nodes: [],
    Edges: [],
  },
  {
    id: "24",
    title: 'Директор филиала АО "ЭнергосбыТ Плюс"',
    Nodes: [],
    Edges: [],
  },
];

export const chpChemicalService: Nodes = {
  title: "Химический цех",
  id: "1",
  Nodes: [
    {
      id: "1",
      position: { x: 300, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Аппаратчик химводоочистки 2,3,4,5 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "2",
      position: { x: 300, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Аппаратчик по приготовлению химреагентов 3,4 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "3",
      position: { x: 300, y: 600 },
      data: {
        label: (
          <Link to="/position">
            <span>Аппаратчик по очистке сточных вод 3,4 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "4",
      position: { x: 300, y: 700 },
      data: {
        label: (
          <Link to="/position">
            <span>Лаборант химического анализа 3,4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "5",
      position: { x: 300, y: 800 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Слесарь по обслуживанию оборудования электростанций 3,4 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "6",
      position: { x: 800, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены цеха</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "7",
      position: { x: 800, y: 710 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер 1 категории</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "8",
      position: { x: 1200, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер отдела химводоподгото вки </span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "9-t",
      position: { x: 1200, y: 600 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "9-b",
      position: { x: 1200, y: 600 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "9",
      position: { x: 1200, y: 600 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "10",
      position: { x: 1200, y: 800 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер ПТО</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "11-t",
      position: { x: 1600, y: 586 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника ХЦ/ Главный специалист</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "11-b",
      position: { x: 1600, y: 586 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника ХЦ/ Главный специалист</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "11",
      position: { x: 1600, y: 586 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника ХЦ/ Главный специалист</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "12",
      position: { x: 1600, y: 800 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ПТО</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "13",
      position: { x: 1900, y: 600.5 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ХЦ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "14",
      position: { x: 2000, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ЦХЛ</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "15",
      position: { x: 2000, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Главный специалист БТиОЗ</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "16",
      position: { x: 2000, y: 300 },
      data: {
        id: "47",
        label: (
          <Link to="/position">
            <span>Начальник отдела химводоподготовки</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
  ],
  Edges: [
    {
      id: "e1-6",
      source: "1",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e3-6",
      source: "3",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e4-6",
      source: "4",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e5-6",
      source: "5",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e1-7",
      source: "1",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e2-7",
      source: "2",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e3-7",
      source: "3",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },

    {
      id: "e5-7",
      source: "5",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e2-6",
      source: "2",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="80" dy="0">
            С любой должности через
          </tspan>
          <tspan x="80" dy="20">
            стажировку и дублирование
          </tspan>
          <tspan x="80" dy="20">
            Стаж при ВПО – 1 год,
          </tspan>
          <tspan x="80" dy="20">
            Стаж при СПО – 3 года
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e4-7",
      source: "4",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВПО , 3 года",
    },
    {
      id: "e6-9",
      source: "6",
      target: "9",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВПО, 2 года",
    },
    {
      id: "e7-9",
      source: "7",
      target: "9",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВПО, 2 года",
    },
    {
      id: "e9-8",
      source: "9-t",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e9-10",
      source: "9-b",
      target: "10",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e9-11",
      source: "9",
      target: "11",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВПО, 2-3 года",
    },
    {
      id: "e11-12",
      source: "11-b",
      target: "12",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e11-13",
      source: "11",
      target: "13",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВПО, 5 лет",
    },
    {
      id: "e11-14",
      source: "11-t",
      target: "14",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e11-15",
      source: "11-t",
      target: "15",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e11-16",
      source: "11-t",
      target: "16",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
  ],
};

export const chpElectricalService: Nodes = {
  title: "Электрический цех",
  id: "2",
  Nodes: [
    {
      id: "1",
      position: { x: 300, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Аппаратчик электролиза 3,4,5 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "2",
      position: { x: 300, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Аккумуляторщик 3,4,5 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "3-t",
      position: { x: 600, y: 250 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электромонтер по обслуживанию электрооборудования электростанций
              4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "3",
      position: { x: 600, y: 250 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электромонтер по обслуживанию электрооборудования электростанций
              4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "4",
      position: { x: 600, y: 600 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электромонтер по ремонту аппаратуры, релейной защиты и автоматики
              4,5,6,7 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "5",
      position: { x: 600, y: 900 },
      data: {
        label: (
          <Link to="/position">
            <span>Электромонтер по испытаниям и измерениям 4 разряда</span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "6",
      position: { x: 900, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электромонтер главного щита управления электространции 4,5 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "7-t",
      position: { x: 900, y: 730 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Мастер/Мастер производственного участка/Мастер по ремонту
              оборудования Электротехнической лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "7-b",
      position: { x: 900, y: 730 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Мастер/Мастер производственного участка/Мастер по ремонту
              оборудования Электротехнической лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Bottom,
    },
    {
      id: "7",
      position: { x: 900, y: 730 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Мастер/Мастер производственного участка/Мастер по ремонту
              оборудования Электротехнической лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "8-b",
      position: { x: 1200, y: 243 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Старший электромонтер по обслуживанию электрооборудования
              электростанций 5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "8",
      position: { x: 1200, y: 243 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Старший электромонтер по обслуживанию электрооборудования
              электростанций 5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "9",
      position: { x: 1200, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер/ Инженер ЭЦ 1 категории</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "10-t",
      position: { x: 1200, y: 744.5 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Старший мастер производственного участка Электротехнической
              лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "10",
      position: { x: 1200, y: 744.5 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Старший мастер производственного участка Электротехнической
              лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "11-b",
      position: { x: 1200, y: 1000 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Старший мастер производственного участка Электротехнической
              лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "11",
      position: { x: 1200, y: 1000 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Старший мастер производственного участка Электротехнической
              лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "12-t",
      position: { x: 1600, y: 272 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены Электрического цеха</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Top,
    },
    {
      id: "12-b",
      position: { x: 1600, y: 272 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены Электрического цеха</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Bottom,
    },
    {
      id: "12",
      position: { x: 1600, y: 272 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены Электрического цеха</span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "13-b",
      position: { x: 2000, y: 744.5 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Начальник электротехнической лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "13-t",
      position: { x: 2000, y: 744.5 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Начальник электротехнической лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Top,
    },
    {
      id: "13",
      position: { x: 2000, y: 744.5 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Начальник электротехнической лаборатории Электрического цеха
            </span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "14-t",
      position: { x: 2200, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника электрического цеха</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "14-b",
      position: { x: 2200, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника электрического цеха</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "14",
      position: { x: 2200, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника электрического цеха</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "15",
      position: { x: 2700, y: 507 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник электрического цеха</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "16",
      position: { x: 2700, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены станции</span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "17-t",
      position: { x: 1400, y: 45 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник участка ЦРС</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Top,
    },
    {
      id: "17",
      position: { x: 1400, y: 45 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник участка ЦРС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "18",
      position: { x: 2200, y: 0 },
      data: {
        label: (
          <Link to="/position">
            <span>лавный специалист Электрического отдела АУП</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "19",
      position: { x: 2200, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер Электрического отдела АУП</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "20-t",
      position: { x: 2450, y: 700 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ЦРС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Top,
      targetPosition: Position.Top,
    },
    {
      id: "20",
      position: { x: 2450, y: 700 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ЦРС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Right,
    },
  ],
  Edges: [
    {
      id: "e9-17",
      source: "9",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e17-20",
      source: "17",
      target: "20-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e15-20",
      source: "15",
      target: "20",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e12-18",
      source: "12-t",
      target: "18",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e12-19",
      source: "12-t",
      target: "19",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e13-17",
      source: "13-t",
      target: "17-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e1-3",
      source: "1",
      target: "3",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e1-4",
      source: "1",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 0,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e2-3",
      source: "2",
      target: "3",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e2-4",
      source: "2",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 0,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e3-6",
      source: "3-t",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e4-7",
      source: "4",
      target: "7-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e3-8",
      source: "3",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: "ВПО, СПО, 1 год электромонтером",
    },
    {
      id: "e8-9",
      source: "8-b",
      target: "9",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e7-10",
      source: "7",
      target: "10",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e5-11",
      source: "5",
      target: "11-b",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВПО, 3 года",
    },
    {
      id: "e5-7",
      source: "5",
      target: "7-b",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e7-11",
      source: "7-b",
      target: "11",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e8-12",
      source: "8",
      target: "12",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e6-12",
      source: "6",
      target: "12-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e10-13",
      source: "10-t",
      target: "13",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e11-13",
      source: "11",
      target: "13-b",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="80" dy="0">
            ВПО, стаж работы на ИТР
          </tspan>
          <tspan x="80" dy="20">
            должностях не менее 3 лет
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e10-14",
      source: "10",
      target: "14",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e13-14",
      source: "13-t",
      target: "14-b",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e12-14",
      source: "12-b",
      target: "14-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e14-15",
      source: "14",
      target: "15",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="105" dy="0">
            ВПО, стаж работы на ИТР должностях
          </tspan>
          <tspan x="105" dy="20">
            не менее 5 лет
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e12-16",
      source: "12",
      target: "16",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="105" dy="0">
            СПО/ВПО,
          </tspan>
          <tspan x="105" dy="20">
            опыт работы не менее 3 лет/
          </tspan>
          <tspan x="105" dy="20">
            1 года на должностях старшего
          </tspan>
          <tspan x="105" dy="20">
            оперативного персонала КО, ТО, ЭЦ.
          </tspan>
          <tspan x="105" dy="20">
            Подготовка по должности 1 год
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e9-14",
      source: "9",
      target: "14",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="55" dy="0">
            ВПО, стаж работы
          </tspan>
          <tspan x="55" dy="20">
            на ИТР должностях
          </tspan>
          <tspan x="55" dy="20">
            не менее 3 лет
          </tspan>
        </tspan>
      ),
    },
  ],
};

export const chpAcsService: Nodes = {
  id: "3",
  title: "Цех АСУТП",
  Nodes: [
    {
      id: "1",
      position: { x: 300, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электрослесарь по обслуживанию автоматики и средств измерений
              электростанций 4,5,6,7 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "2-t",
      position: { x: 700, y: 436 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "2",
      position: { x: 700, y: 436 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "3-b",
      position: { x: 1100, y: 430 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены цеха</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "3",
      position: { x: 1100, y: 430 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены цеха</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "4",
      position: { x: 1100, y: 700 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены станции</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "5-t",
      position: { x: 1500, y: 430 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника цеха АСУТП</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Top,
    },
    {
      id: "5-b",
      position: { x: 1500, y: 430 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника цеха АСУТП</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "5",
      position: { x: 1500, y: 430 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника цеха АСУТП</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "6",
      position: { x: 1500, y: 300 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер ПТО</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "7",
      position: { x: 1800, y: 434 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник цеха АСУТП </span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "8",
      position: { x: 1500, y: 700 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий специалист управления АСУТП</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "9",
      position: { x: 1500, y: 800 },
      data: {
        label: (
          <Link to="/position">
            <span>Руководитель службы АСУТП ТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
  ],
  Edges: [
    {
      id: "e1-2",
      source: "1",
      target: "2",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВПО, 3 года",
    },
    {
      id: "e5-7",
      source: "5",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВПО, 5 лет",
    },
    {
      id: "e2-3",
      source: "2",
      target: "3",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="35" dy="0">
            ВПО, 2 года
          </tspan>
          <tspan x="35" dy="20">
            НПО, 3 года
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e3-4",
      source: "3-b",
      target: "4",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="130" dy="0">
            СПО/ВПО, опыт работы не менее 3 лет/ 1 года
          </tspan>
          <tspan x="130" dy="20">
            на должностях
          </tspan>
          <tspan x="130" dy="20">
            старшего оперативного
          </tspan>
          <tspan x="130" dy="20">
            персонала КО, ТО, ЭЦ
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e3-5",
      source: "3",
      target: "5",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "ВПО, 1 год",
    },
    {
      id: "e2-5",
      source: "2-t",
      target: "5-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e5-6",
      source: "5-t",
      target: "6",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e5-8",
      source: "5-b",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e5-9",
      source: "5-b",
      target: "9",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
  ],
};

export const ahpBoilerTurbine: Nodes = {
  id: "4",
  title: "Котлотурбинный цех",
  Nodes: [
    {
      id: "1",
      position: { x: 300, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Машинист компрессорных установок</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "2",
      position: { x: 300, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Слесарь по обслуживанию оборудования электростанции</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "3",
      position: { x: 300, y: 300 },
      data: {
        label: (
          <Link to="/position">
            <span>Машинист береговых насосных станций</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "4",
      position: { x: 300, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Машинист крана</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "5",
      position: { x: 300, y: 500 },
      data: {
        label: (
          <Link to="/position">
            <span>Машинист топливоподачи</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "6",
      position: { x: 300, y: 600 },
      data: {
        label: (
          <Link to="/position">
            <span>Машинист насосных установок</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "7",
      position: { x: 600, y: 250 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Машинист-обходчик по котельному оборудованию 3,4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "8",
      position: { x: 600, y: 460 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Машинист-обходчик по турбинному оборудованию 3,4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "9-t",
      position: { x: 1000, y: 250 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Машинист ЦТЩУ котлами 5,6,7 разряда Машинист котлов 4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "9",
      position: { x: 1000, y: 250 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Машинист ЦТЩУ котлами 5,6,7 разряда Машинист котлов 4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "10",
      position: { x: 1000, y: 450 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Машинист ЦТЩУ паровыми турбинами 5,6,7 разряда Машинист паровых
              турбин 3,4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "11",
      position: { x: 700, y: 50 },
      data: {
        label: (
          <Link to="/position">
            <span>БЛОК ПГУ Машинист-обходчик энергоблока 4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "12-b",
      position: { x: 1100, y: 42.5 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Машинист энергоблока 7,8 разряда Машинист газотурбинных установок
              7 разряда
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "12",
      position: { x: 1100, y: 42.5 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Машинист энергоблока 7,8 разряда Машинист газотурбинных установок
              7 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "13",
      position: { x: 1500, y: 56.5 },
      data: {
        label: (
          <Link to="/position">
            <span>Старший машинист энергоблока 8 разряда</span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "14-b",
      position: { x: 1400, y: 350 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Старший машинист котельного/турбинного о6орудования 7,8 разряда
              Старший машинист КТЦ
            </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Bottom,
    },
    {
      id: "14",
      position: { x: 1400, y: 350 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Старший машинист котельного/турбинного о6орудования 7,8 разряда
              Старший машинист КТЦ
            </span>
          </Link>
        ),
      },
      className: "card card-worker",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "15-t",
      position: { x: 1800, y: 375 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены КТЦ</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "15-b",
      position: { x: 1800, y: 375 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены КТЦ</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Bottom,
    },
    {
      id: "15",
      position: { x: 1800, y: 375 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник смены КТЦ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "16-t",
      position: { x: 1800, y: 550 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер КТЦ/ Инженер КТЦ</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Top,
    },
    {
      id: "16-b",
      position: { x: 1800, y: 550 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер КТЦ/ Инженер КТЦ</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Top,
    },
    {
      id: "16",
      position: { x: 1800, y: 550 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер КТЦ/ Инженер КТЦ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "17",
      position: { x: 2050, y: 0 },
      data: {
        label: (
          <Link to="/position">
            <span>Главный специалист Теплотехнического управления</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "18-b",
      position: { x: 2200, y: 250 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника КТЦ</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Bottom,
    },
    {
      id: "18",
      position: { x: 2200, y: 250 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника КТЦ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "19",
      position: { x: 2200, y: 450 },
      data: {
        label: (
          <Link to="/position">
            <span>Главный специалист КТЦ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Bottom,
    },
    {
      id: "20",
      position: { x: 2300, y: 650 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер ПТО</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Right,
    },
    {
      id: "21",
      position: { x: 2300, y: 750 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер отдела ОТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Right,
    },
    {
      id: "22",
      position: { x: 2300, y: 850 },
      data: {
        label: (
          <Link to="/position">
            <span>Ведущий инженер теплотехнического отдела ПТУ</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Right,
    },
    {
      id: "23-t",
      position: { x: 2600, y: 382 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник КТЦ</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "23",
      position: { x: 2600, y: 382 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник КТЦ</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "24",
      position: { x: 2800, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ПТО</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "25",
      position: { x: 2800, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ЦРС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
  ],
  Edges: [
    {
      id: "e1-8",
      source: "1",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e18-23",
      source: "18-b",
      target: "23",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: "ВПО, стаж работы ИТР не менее 5 лет",
    },
    {
      id: "e2-8",
      source: "2",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e3-8",
      source: "3",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e4-8",
      source: "4",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e5-8",
      source: "5",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e6-8",
      source: "6",
      target: "8",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e1-7",
      source: "1",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e2-7",
      source: "2",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e3-7",
      source: "3",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e4-7",
      source: "4",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e5-7",
      source: "5",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e6-7",
      source: "6",
      target: "7",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e7-9",
      source: "7",
      target: "9",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: "Стаж МОКО не менее 1 года",
    },
    {
      id: "e8-10",
      source: "8",
      target: "10",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: "Стаж МОКО не менее 1 года",
    },
    {
      id: "e11-12",
      source: "11",
      target: "12",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: "Стаж МОЭ не менее 1 года",
    },
    {
      id: "e9-12",
      source: "9-t",
      target: "12-b",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e12-13",
      source: "12",
      target: "13",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="65" dy="0">
            Стаж работы
          </tspan>
          <tspan x="65" dy="20">
            на всех рабочих местах
          </tspan>
          <tspan x="65" dy="20">
            энергоблока
          </tspan>
          <tspan x="65" dy="20">
            Не менее 6 мес
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e9-14",
      source: "9",
      target: "14",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: "Стаж не менее 6 мес, техническое образование",
    },
    {
      id: "e10-14",
      source: "10",
      target: "14-b",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: "Стаж не менее 6 мес, техническое образование",
    },
    {
      id: "e14-15",
      source: "14",
      target: "15",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="32" dy="0">
            1 год ВО,
          </tspan>
          <tspan x="32" dy="20">
            3 года СПО
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e13-15",
      source: "13",
      target: "15-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
    },
    {
      id: "e14-16",
      source: "14-b",
      target: "16",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "Высшее, СПО, стаж 1 год",
    },
    {
      id: "e15-16",
      source: "15-b",
      target: "16-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e16-15",
      source: "16-t",
      target: "15-b",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "При условии оперативной работы",
    },
    {
      id: "e15-17",
      source: "15",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e16-18",
      source: "16",
      target: "18",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e16-19",
      source: "16",
      target: "19",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e15-18",
      source: "15",
      target: "18-b",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#2E96E2",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#2E96E2",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="75" dy="0">
            ВПО, 5-7 лет общих стаж,
          </tspan>
          <tspan x="75" dy="20">
            1 год управленческий стаж
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e16-20",
      source: "16-b",
      target: "20",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e16-21",
      source: "16-b",
      target: "21",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e18-23",
      source: "18",
      target: "23-t",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e23-25",
      source: "23",
      target: "25",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e23-24",
      source: "23",
      target: "24",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
  ],
};

export const ahpRepair = {
  id: "5",
  title: "Центральная ремонтная служба",
  Nodes: [
    {
      id: "1",
      position: { x: 300, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Слесарь по ремонту оборудования котельных и пылеприготовительных
              цехов 4,5,6 разрядов
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "9",
      position: { x: 500, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Слесарь-сантехник 4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "2",
      position: { x: 300, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Слесарь по ремонту парогазотурбинного оборудования 3,4,5,6
              разрядов
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "10",
      position: { x: 500, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Электрослесарь по ремонту электрических машин</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "3",
      position: { x: 300, y: 300 },
      data: {
        label: (
          <Link to="/position">
            <span>Токарь 5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "11",
      position: { x: 500, y: 300 },
      data: {
        label: (
          <Link to="/position">
            <span>Огнеупорщик 4,5 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "4",
      position: { x: 300, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электрослесарь по ремонту и обслуживанию автоматики и средств
              измерений электростанций 4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "12",
      position: { x: 500, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>Плотник 4,5,6 разряа</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "5",
      position: { x: 300, y: 550 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электрослесарь по ремонту электрооборудования электростанций
              4,5,6,7,8 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "13",
      position: { x: 500, y: 550 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Электромонтер по ремонту и обслуживанию электрооборудования
              4,5,6,7 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "6",
      position: { x: 300, y: 670 },
      data: {
        label: (
          <Link to="/position">
            <span>Слесарь-ремонтник 4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "14",
      position: { x: 500, y: 670 },
      data: {
        label: (
          <Link to="/position">
            <span>Электрогазосварщик 3,4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "7",
      position: { x: 300, y: 750 },
      data: {
        label: (
          <Link to="/position">
            <span>Токарь 5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "15",
      position: { x: 500, y: 750 },
      data: {
        label: (
          <Link to="/position">
            <span>Изолировщик на термоизоляции 3,4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "8",
      position: { x: 300, y: 850 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Слесарь по ремонту оборудования тепловых сетей 2,3,4,5,6 разряда
            </span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "16",
      position: { x: 500, y: 850 },
      data: {
        label: (
          <Link to="/position">
            <span>Электрогазосварщик 3,4,5,6 разряда</span>
          </Link>
        ),
      },
      className: "card card-start",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "17",
      position: { x: 900, y: 400 },
      data: {
        label: (
          <Link to="/position">
            <span>
              Мастер/ Мастер 1 группы/Мастер 2 группы/Мастер 3 группы/Мастер ПУ
            </span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "18-b",
      position: { x: 1400, y: 422 },
      data: {
        label: (
          <Link to="/position">
            <span>Старший мастер</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "18-t",
      position: { x: 1400, y: 422 },
      data: {
        label: (
          <Link to="/position">
            <span>Старший мастер</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "18",
      position: { x: 1400, y: 422 },
      data: {
        label: (
          <Link to="/position">
            <span>Старший мастер</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "19",
      position: { x: 1400, y: 600 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер/Главный специалист ЦРС</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Top,
    },
    {
      id: "20",
      position: { x: 1600, y: 0 },
      data: {
        label: (
          <Link to="/position">
            <span>Инженер управления ОТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "21-t",
      position: { x: 1800, y: 422 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник участка</span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "21",
      position: { x: 1800, y: 422 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник участка</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "22-b",
      position: { x: 2100, y: 415 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника ЦРС </span>
          </Link>
        ),
      },
      className: "card",
      sourcePosition: Position.Bottom,
      targetPosition: Position.Left,
    },
    {
      id: "22",
      position: { x: 2100, y: 415 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель начальника ЦРС </span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "23",
      position: { x: 2400, y: 422 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ЦРС</span>
          </Link>
        ),
      },
      className: "card card-supervisor",
      sourcePosition: Position.Top,
      targetPosition: Position.Left,
    },
    {
      id: "24",
      position: { x: 1950, y: 0 },
      data: {
        label: (
          <Link to="/position">
            <span>Главный специалист отдела ОТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "25",
      position: { x: 1950, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник отдела ремонтов</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "26",
      position: { x: 1850, y: 600 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель главного инженера по ОТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Right,
    },
    {
      id: "27",
      position: { x: 1850, y: 700 },
      data: {
        label: (
          <Link to="/position">
            <span>Главный специалист отдела ОТС </span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Left,
      targetPosition: Position.Right,
    },
    {
      id: "28",
      position: { x: 1850, y: 800 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник отдела ремонтов</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Left,
      targetPosition: Position.Right,
    },
    {
      id: "29",
      position: { x: 2550, y: 0 },
      data: {
        label: (
          <Link to="/position">
            <span>Заместитель главного инженера по ОТС</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "30",
      position: { x: 2550, y: 100 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник ПТУ</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
    {
      id: "31",
      position: { x: 2550, y: 200 },
      data: {
        label: (
          <Link to="/position">
            <span>Начальник управления АСУТП</span>
          </Link>
        ),
      },
      className: "card card-another",
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    },
  ],
  Edges: [
    {
      id: "e23-29",
      source: "23",
      target: "29",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e23-30",
      source: "23",
      target: "30",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e23-31",
      source: "23",
      target: "31",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e22-26",
      source: "22-b",
      target: "26",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e22-27",
      source: "22-b",
      target: "27",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e22-28",
      source: "22-b",
      target: "28",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e21-24",
      source: "21-t",
      target: "24",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e21-25",
      source: "21-t",
      target: "25",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
    {
      id: "e22-23",
      source: "22",
      target: "23",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: "5 лет, ВО",
    },
    {
      id: "e21-22",
      source: "21",
      target: "22",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e18-21",
      source: "18",
      target: "21",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="36" dy="0">
            3-5 лет – ВО,
          </tspan>
          <tspan x="36" dy="20">
            5 лет - СПО
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e9-17",
      source: "9",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e10-17",
      source: "10",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e11-17",
      source: "11",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },

    {
      id: "e13-17",
      source: "13",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e14-17",
      source: "14",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e15-17",
      source: "15",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e16-17",
      source: "16",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e12-17",
      source: "12",
      target: "17",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="38" dy="0">
            1 год – ВО
          </tspan>
          <tspan x="38" dy="20">
            3 года – СПО
          </tspan>
          <tspan x="38" dy="20">
            5 лет – без
          </tspan>
          <tspan x="38" dy="20">
            образования
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e17-18",
      source: "17",
      target: "18",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
      label: (
        <tspan x="0" y="10" text-anchor="middle">
          <tspan x="36" dy="0">
            1 год ВО
          </tspan>
          <tspan x="36" dy="20">
            3 года – СПО
          </tspan>
        </tspan>
      ),
    },
    {
      id: "e18-19",
      source: "18-b",
      target: "19",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#F56632",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#F56632",
      },
      type: "smoothstep",
    },
    {
      id: "e18-20",
      source: "18-t",
      target: "20",
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#39D2A3",
        strokeWidth: 2,
      },
      style: {
        strokeWidth: 3,
        stroke: "#39D2A3",
      },
      type: "smoothstep",
    },
  ],
};
